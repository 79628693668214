import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from '@pages/login-page/login-page.component';
import { authGuard } from '@auth/guards/auth.guard';
import { BrowserUtils } from '@azure/msal-browser';
import { AppFramePageComponent } from '@core/components/app-frame-page/app-frame-page.component';
import { ForgotPasswordPageComponent } from '@pages/forgot-password-page/forgot-password-page.component';
import { PasswordResetPageComponent } from '@pages/password-reset-page/password-reset-page.component';
import { EmailVerificationPageComponent } from '@pages/email-verification-page/email-verification-page.component';
import { AuthenticatedRoutes } from '@app/routes/authenticated.routes';
import { SsoRedirectComponent } from '@auth/components/sso-redirect/sso-redirect.component';
import { ssoAuthGuard } from '@auth/guards/sso-auth.guard';
import { learningRoutes } from '@app/routes/learning-activity.routes';
import { DiscussionRoutes } from './routes/discussion.routes';
import { RegistrationPageComponent } from '@pages/registration-page/registration-page.component';

const routes: Routes = [
  {
    path: '',
    component: AppFramePageComponent,
    canActivate: [authGuard],
    children: AuthenticatedRoutes
  },

  {
    path: 'login',
    data: { title: 'Sign In' },
    component: LoginPageComponent
  },
  {
    path: 'registration',
    data: { title: 'Register and Enroll' },
    component: RegistrationPageComponent
  },
  {
    path: 'authorize',
    data: { title: 'Authorize' },
    component: SsoRedirectComponent,
    canActivate: [ssoAuthGuard]
  },
  {
    path: 'forgot-password',
    data: { title: 'Forgot Password' },
    component: ForgotPasswordPageComponent
  },
  {
    path: 'password-reset',
    data: { title: 'Password Reset' },
    component: PasswordResetPageComponent
  },
  {
    path: 'verify-email',
    data: { title: 'Email Verification' },
    component: EmailVerificationPageComponent
  },
  ...learningRoutes,
  ...DiscussionRoutes,
  {
    path: '**',
    redirectTo: '/',
  }
];

export const route = (path: string) => routes.find(route => route.path === path);

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation:
      !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
        ? 'enabledNonBlocking'
        : 'disabled', // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
