import { Component, Input, OnInit } from '@angular/core';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { QuizSubmissionPart } from '@app/api/models/learning-activity.model';
import { QuestionData } from '@app/api/question/models/question-data.model';
import {
  QuizLearningActivity,
  QuizSubmission,
} from '@app/api/models/quiz-learning-activity';
import { QuizData } from '@app/api/quiz/models/quiz.model';
import { UserRole } from '@app/api/user/models/user.model';
import { UserService } from '@app/api/user/services/user.service';

@Component({
  selector: 'app-quiz-results',
  templateUrl: './quiz-results.component.html',
  styleUrls: ['./quiz-results.component.sass'],
})
export class QuizResultsComponent implements OnInit {
  @Input({ required: true }) quiz!: QuizData;
  @Input({ required: true }) questions: QuestionData[] = [];
  @Input({ required: true }) learningActivity!: QuizLearningActivity;
  @Input() submission?: QuizSubmission;
  questionSubmission: QuizSubmissionPart[] = [];
  userRole: UserRole | undefined;
  newScore: number | null = null;
  isEditingScore = false; // Track whether the user is editing the score
  updatedScore?: number; // Store the updated score value
  updateMessage: string | null = null;

  collapsedItems = new Map<number, boolean>();
  showAnswers = false;
  scoreIsInvalid = false;

  constructor(
    private learningActivityService: LearningActivityService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    if (this.submission) {
      this.submission.submissionParts.forEach((part) => {
        const question = this.questions.find((q) => q.id == part.question.id);
        if (question) {
          this.questionSubmission.push({ ...part, question: question! });
        }
      });
    } else {
      this.learningActivityService
        .getQuizSubmission(this.learningActivity.id)
        .subscribe((value) => {
          this.submission = value;
          this.submission.submissionParts.forEach((part) => {
            const question = this.questions.find(
              (q) => q.id == part.question.id,
            );
            if (question) {
              this.questionSubmission.push({ ...part, question: question! });
            }
          });
        });
    }
    this.getUserRole();
  }

  getUserRole() {
    this.userService.getSelfRole().subscribe((role) => {
      this.userRole = role;
    });
  }

  isCorrect(submission: QuizSubmissionPart): boolean {
    return (
      submission.answer.toLowerCase() ===
      this.getCorrectAnswer(submission).toLowerCase()
    );
  }

  getCorrectAnswer(submission: QuizSubmissionPart): string {
    const question = submission.question;
    switch (question.type) {
      case 'MULTIPLE_CHOICE': {
        if (question.selectAllThatApply) {
          const correctAnswers = question.choices
            ?.filter((c) => c.correct)
            .map((c) => c.id?.toString() ?? '');
          return correctAnswers?.join(',') ?? '';
        }
        return question.choices?.find((c) => c.correct)?.id?.toString() ?? '';
      }
      case 'FILL_IN_THE_BLANK':
        return this.getCorrectFitbAnswers(submission).join(', ');
      case 'TRUE_FALSE':
        return question.trueFalseCorrect ? 'True' : 'False';
      case 'SHORT_ANSWER':
        return question.correctAnswer?.correctAnswer ?? '';
      case 'INTERVIEW':
        return question.correctAnswer?.correctAnswer ?? '';
    }
  }

  getExplanation(submission: QuizSubmissionPart): string {
    const question = submission.question;
    switch (question.type) {
      case 'MULTIPLE_CHOICE': {
        return question.choices?.find((c) => c.correct)?.explanation ?? '';
      }
      case 'FILL_IN_THE_BLANK':
        return question.correctAnswer?.explanation ?? '';
      case 'TRUE_FALSE':
        return question.trueFalseExplanation ?? '';
      case 'SHORT_ANSWER':
        return question.correctAnswer?.explanation ?? '';
      case 'INTERVIEW':
        return question.correctAnswer?.explanation ?? '';
    }
  }

  getScore() {
    return this.questionSubmission.filter((s) => this.isCorrect(s)).length;
  }

  getCorrectFitbAnswers(sub: QuizSubmissionPart): string[] {
    // Return an array of correct answers for fill in the blank questions
    const correctAnswers: string[] = [];
    const fitbAnswerRegex = /\{([^}|]+)}|\{([^}]+)\|([^}]+)}/g;

    let match: RegExpExecArray | null;
    while (
      (match = fitbAnswerRegex.exec(sub.question?.fillInTheBlankText ?? '')) !==
      null
    ) {
      for (let i = 0; i < match.length; i++) {
        if (match[i]) {
          correctAnswers.push(this.sanitizeFitbAnswer(match[i]));
          break;
        }
      }
    }
    return correctAnswers;
  }

  sanitizeFitbAnswer(answer?: string): string {
    return answer?.replace(/\{|\}/g, '') ?? '';
  }

  startEditScore(): void {
    this.isEditingScore = true;
    this.updatedScore = (this.submission?.score ?? 0) * 100;
  }

  cancelEditScore(): void {
    this.isEditingScore = false;
  }

  submitScore(): void {
    if (this.scoreIsInvalid) {
      return;
    }
    if (this.updatedScore !== undefined && !isNaN(this.updatedScore)) {
      const learningActivityId = this.learningActivity.id;
      this.learningActivityService
        .updateQuizScore(learningActivityId, this.updatedScore / 100)
        .subscribe({
          next: (response) => {
            if (this.submission) {
              this.submission.score = this.updatedScore! * 100;
            }
            this.learningActivityService
              .getQuizSubmission(this.learningActivity.id)
              .subscribe((value) => {
                this.submission = value;
                this.questionSubmission = [];
                this.submission.submissionParts.forEach((part) => {
                  const question = this.questions.find(
                    (q) => q.id == part.question.id,
                  );
                  if (question) {
                    this.questionSubmission.push({
                      ...part,
                      question: question!,
                    });
                  }
                });
              });

            this.isEditingScore = false;
            this.updateMessage = 'Quiz score updated successfully!';
            setTimeout(() => {
              this.updateMessage = null;
            }, 5000);
          },
          error: (error) => {
            console.error('Error updating score:', error);
            this.updateMessage = 'Error updating quiz score. Please try again.';
            setTimeout(() => {
              this.updateMessage = null;
            }, 5000);
          },
        });
    }
  }

  onScoreChange($event: number) {
    if (!$event) {
      return;
    }
    if ($event < 0 || $event > 100) {
      this.scoreIsInvalid = true;
    } else {
      this.updatedScore = $event;
      this.scoreIsInvalid = false;
    }
  }
}
