import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { LearningMainPageComponent } from './components/learning-main-page/learning-main-page.component';
import { CoreModule } from '@core/core.module';
import { CourseActivityViewComponent } from './components/course-activity-view/course-activity-view.component';
import { ModuleActivityViewComponent } from './components/module-activity-view/module-activity-view.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { LessonActivityViewComponent } from './components/lesson-activity-view/lesson-activity-view.component';
import { ApiModule } from '@app/api/api.module';
import {
  NgbAccordionBody,
  NgbAccordionButton,
  NgbAccordionCollapse,
  NgbAccordionDirective,
  NgbAccordionHeader,
  NgbAccordionItem,
  NgbAccordionToggle,
  NgbCollapse,
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbPagination,
  NgbTooltip,
  NgbTypeahead,
} from '@ng-bootstrap/ng-bootstrap';
import { ActivityViewComponent } from './components/activity-view/activity-view.component';
import { ActivitySkeletonStructureComponent } from './components/activity-skeleton-structure/activity-skeleton-structure.component';
import { ActivityBottomNavigationComponent } from './components/activity-bottom-navigation/activity-bottom-navigation.component';
import { LearningActivitiesTableComponent } from './components/learning-activities-table/learning-activities-table.component';
import { FormsModule } from '@angular/forms';
import { QuizActivityViewComponent } from './components/quiz-activity-view/quiz-activity-view.component';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [
    LearningMainPageComponent,
    CourseActivityViewComponent,
    ModuleActivityViewComponent,
    LessonActivityViewComponent,
    ActivityViewComponent,
    ActivitySkeletonStructureComponent,
    ActivityBottomNavigationComponent,
    LearningActivitiesTableComponent,
    QuizActivityViewComponent,
  ],
  exports: [
    LearningMainPageComponent,
    ActivityBottomNavigationComponent,
    LearningActivitiesTableComponent,
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    CoreModule,
    RouterLink,
    ApiModule,
    NgbAccordionDirective,
    NgbAccordionItem,
    NgbAccordionHeader,
    NgbAccordionCollapse,
    NgbAccordionBody,
    NgbAccordionButton,
    NgbAccordionToggle,
    RouterOutlet,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownItem,
    NgbCollapse,
    NgbTypeahead,
    FormsModule,
    NgbPagination,
    NgbTooltip,
    MarkdownModule,
  ],
})
export class LearningModule {}
