import { Component, Input, OnInit } from '@angular/core';
import { QuizData } from '@app/api/quiz/models/quiz.model';
import { QuestionData } from '@app/api/question/models/question-data.model';
import { QuestionApiService } from '@app/api/question/services/question-api.service';
import { forkJoin } from 'rxjs';
import { GlobalModalService } from '@core/services/global-modal.service';

@Component({
  selector: 'app-quiz-preview',
  templateUrl: './quiz-preview.component.html',
  styleUrls: ['./quiz-preview.component.sass'],
})
export class QuizPreviewComponent implements OnInit {
  @Input() data!: QuizData;
  loading = true;
  questions: QuestionData[] = [];

  constructor(
    private questionApiService: QuestionApiService,
    private modalService: GlobalModalService,
  ) {}

  ngOnInit(): void {
    this.loadQuestions();
  }

  loadQuestions() {
    this.loading = true;
    const tasks = this.data.questions.map((question) => {
      return this.questionApiService.getQuestionDataForUpdate(
        question.questionData.id!,
      );
    });

    forkJoin(tasks).subscribe({
      next: (questions) => {
        this.questions = questions;
        this.loading = false;
      },
      error: () => {
        this.modalService
          .alert({
            type: 'danger',
            content: 'Failed to load questions',
          })
          .close();
        this.loading = false;
      },
    });
  }
}
