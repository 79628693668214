import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { ActivatedRoute } from '@angular/router';
import { LearningRouteDataService } from '@app/learning/services/learning-route-data.service';
import { UserService } from '@app/api/user/services/user.service';
import { User, UserRole } from '@app/api/user/models/user.model';

@Component({
  selector: 'app-activity-view',
  templateUrl: './activity-view.component.html',
  styleUrls: ['./activity-view.component.sass'],
})
export class ActivityViewComponent implements OnInit, OnChanges {
  @Input() activityId?: number;
  @Input() showNavigation = true;

  learningActivity?: LearningActivity;

  loading = false;

  currentUser?: User;

  constructor(
    public route: ActivatedRoute,
    private learningActivityService: LearningActivityService,
    public learningRouteData: LearningRouteDataService,
    private userService: UserService,
  ) {
    this.route.paramMap.subscribe((params) => {
      this.learningRouteData.updateRouteData(params);

      let activityId = params.get('activityId');

      if (!activityId) {
        activityId = this.activityId?.toString() || null;
      }

      if (activityId) {
        this.activityId = Number(activityId);
        this.loadLearningActivity(this.activityId);
      }
    });
  }

  get userIsStudent() {
    return this.currentUser?.role === UserRole.Student;
  }

  get assignedToUser() {
    return this.learningActivity?.student?.email === this.currentUser?.email;
  }

  ngOnInit() {
    if (this.activityId) {
      this.loadLearningActivity(this.activityId);
    }

    this.loadCurrentUser();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['activityId']) {
      if (this.activityId) {
        this.loadLearningActivity(this.activityId);
      }
    }
  }

  loadLearningActivity(activityId: number) {
    this.loading = true;
    this.learningActivityService.getLearningActivity(activityId).subscribe({
      next: (learningActivity) => {
        this.learningActivity = learningActivity;
        this.loading = false;
      },
    });
  }

  loadCurrentUser() {
    this.userService.getSelf().subscribe((user) => {
      this.currentUser = user;
    });
  }
}
