import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VideoUploadFormService } from '@app/api/video/services/video-upload-form.service';
import { switchMap } from 'rxjs';
import { Router } from '@angular/router';
import { VideoUploadService } from '@app/api/video/services/video-upload.service';
import { environment } from '@env/environment';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-video-upload-preview-and-submit',
  templateUrl: './video-upload-preview-and-submit.component.html',
  styleUrls: ['./video-upload-preview-and-submit.component.sass']
})
export class VideoUploadPreviewAndSubmitComponent {


  watchLink = '';
  uploadProgress = 0;
  videoId = 0;
  errorMessage = '';


  @ViewChild('uploadingVideo') uploadingVideoModal?: TemplateRef<any>;
  @ViewChild('videoUploadSuccess') videoUploadSuccessModal?: TemplateRef<any>;
  @ViewChild('videoUploadError') videoUploadErrorModal?: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private videoUploadFormService: VideoUploadFormService,
    private videoUpload: VideoUploadService,
    private router: Router
  ) {
  }

  get videoBlobUrl() {
    return this.videoUploadFormService.videoBlobUrl;
  }

  get thumbnailBlobUrl() {
    return this.videoUploadFormService.thumbnailBlobUrl;
  }

  get videoUploadForm() {
    return this.videoUploadFormService.videoUploadForm;
  }

  get videoUploadFormData() {
    return this.videoUploadFormService.videoUploadFormData;
  }

  uploadVideo() {
    if (!this.videoUploadFormData) {
      this.errorMessage = 'Cannot upload video without video upload data.';
      this.openVideoUploadErrorModal();
    }
    this.openUploadingVideoModal();
    if (this.videoUploadFormData) {
      this.videoUpload.initiateUpload(this.videoUploadFormData)
        .pipe(
          switchMap((uploadId) => {
            return this.videoUpload.uploadVideo(this.videoUploadFormData!, uploadId);
          }),
        )
        .subscribe({
          next: (videoMetaData) => {
            if (videoMetaData.type === HttpEventType.UploadProgress) {
              const total = videoMetaData.total || 1;
              this.uploadProgress = Math.round(100 * videoMetaData.loaded / total);
            }
            if (videoMetaData.type === HttpEventType.Response && videoMetaData.body) {
              this.videoId = videoMetaData.body.id;
              this.watchLink = environment.clientUrl + '/videos/watch/' + videoMetaData.body.id;
              this.closeUploadingVideoModal();
              this.openVideoUploadSuccessModal();
            }
          },
          error: (error) => {
            this.errorMessage = error.error.message || 'Something went wrong! Please contact your administrator.';
            this.closeUploadingVideoModal();
            this.openVideoUploadErrorModal();
          }
        });
    }
  }


  openUploadingVideoModal() {
    this.modalService.open(this.uploadingVideoModal, { centered: true, backdrop: 'static', keyboard: false });
  }

  openVideoUploadSuccessModal() {
    this.modalService.open(this.videoUploadSuccessModal, { centered: true, backdrop: 'static', keyboard: false });
  }

  openVideoUploadErrorModal() {
    this.modalService.open(this.videoUploadErrorModal, { centered: true, backdrop: 'static', keyboard: false });
  }

  closeErrorModal() {
    this.modalService.dismissAll();
  }

  closeUploadingVideoModal() {
    this.modalService.dismissAll();
  }

  editAgain() {
    this.videoUploadForm.reset();
    this.router.navigate(['videos', 'edit', this.videoId]).then(value => {
      this.closeUploadingVideoModal();
    });
  }

  watchNow() {
    this.videoUploadForm.reset();
    this.router.navigate(['videos', 'watch', this.videoId]).then(() => {
      this.closeUploadingVideoModal();
    });
  }

}
