import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { VideoService } from '@app/api/video/services/video.service';
import { VideoMetaData } from '@app/api/video/interfaces/video-meta-data.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth/services/auth.service';
import { UserRole } from '@app/api/user/models/user.model';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { GlobalToastService } from '@app/core/services/global-toast.service';

@Component({
  selector: 'app-video-selector',
  templateUrl: './video-selector.component.html',
  styleUrls: ['./video-selector.component.sass']
})
export class VideoSelectorComponent implements OnInit {

  @ViewChild('insertVideoFileModal') insertVideoFileModal?: TemplateRef<any>;
  @Input() userEmail = '';
  @Input() selectionModeEnabled = false;
  @Output() videoToInsert = new EventEmitter<VideoMetaData>();
  protected readonly UserRole = UserRole;
  currentPage = 1;
  pageSize = 10;
  search = '';
  videosList: VideoMetaData[] = [];
  totalItems = 10;
  videosLoading = true;
  placeholderItems = [...Array(10).keys()];

  constructor(
    private videoService: VideoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    readonly authService: AuthService,
    private globalModal: GlobalModalService,
    private toastService: GlobalToastService
  ) {
  }

  ngOnInit(): void {
    this.loadVideos();
  }

  loadVideos() {
    this.videosLoading = true;

    const queryParams: any = {
      // search: this.search  || '',
      page: this.currentPage,
      size: this.pageSize,
    };
    if (this.search.trim()) {
      queryParams.search = this.search;
    } else {
      queryParams.search = null;
    }

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });

    this.videoService.getAllVideos(this.search, {
      size: this.pageSize,
      page: this.currentPage - 1,
      sort: ['id,desc']
    }).subscribe({


      next: (response) => {
        this.totalItems = response.totalElements;
        this.videosList = response.content;// Update your component state
        this.videosLoading = false;
      },
      error: (error) => console.error('Error updating videos:', error),
    });

  }

  insertVideo(video: VideoMetaData) {
    this.videoToInsert.emit(video);
  }

  deleteVideo(videoId: number) {
    this.globalModal.confirm('Are you sure you want to delete this Video?').subscribe({
      next: (confirmed: boolean) => {
        if (confirmed) {
          this.videoService.deleteVideo(videoId).subscribe({
            next: (response) => {
              this.toastService.showSuccessToast('Video deleted successfully.');
              this.loadVideos();
              console.log('Video deleted', response);
            },
            error: (error) => {
              this.toastService.showDangerToast('Failed to delete the Video.');
              console.error('Error deleting Video', error);
            },
            complete: () => {
              console.log('Deletion process complete.');
            }
          });
        } else {
          console.log('Deletion cancelled');
        }
      },
      error: (err) => {
        console.error('Error in confirmation', err);
      }
    });
  }
  
  protected readonly Array = Array;
}
