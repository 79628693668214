import { Directive, ElementRef } from '@angular/core';
import { AnalyticsService } from '@core/services/analytics.service';

@Directive({
  selector: '[appClickEvent]',
})
export class ClickEventDirective {
  constructor(
    private clickEventService: AnalyticsService,
    private elementRef: ElementRef,
  ) {
    this.clickEventService.trackClick(this.elementRef.nativeElement);
  }
}
