<div *ngIf="showFrame; else markdownElement" class="border rounded my-2">
  <div class="border-bottom d-flex align-items-center py-2">
    <div class="mx-2 pb-2 flex-grow-1">
      <span class="bi bi-markdown"></span> {{frameTitle}}
    </div>
    <div *ngIf="showExport" ngbDropdown class="m-2">
      <button type="button"
              ngbDropdownToggle
              class="btn btn-sm btn-outline-primary border-0">
        <span class="bi bi-three-dots-vertical"></span>
      </button>
      <div ngbDropdownMenu>
        <button ngbDropdownItem>
          Export as PDF
        </button>
        <button ngbDropdownItem>
          Export as HTML
        </button>
        <button ngbDropdownItem>
          Download markdown
        </button>
      </div>
    </div>
  </div>
  <div class="container-fluid py-2">
    <p *ngIf="!data; else markdownElement" class="text-muted">Preview markdown here...</p>
  </div>
</div>

<script src="https://unpkg.com/x-frame-bypass" type="module"></script>

<ng-template #markdownElement>
  <markdown
    [data]="data"
    class="markdown-viewer">
  </markdown>
</ng-template>
