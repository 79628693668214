import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { StringResourceComponent } from './components/string-resource/string-resource.component';
import { StringResourcePipe } from './pipes/string-resource.pipe';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import {
  NgbDropdown,
  NgbDropdownAnchor,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbPagination,
  NgbProgressbar,
  NgbToast,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { TextFieldComponent } from './components/forms/text-field/text-field.component';
import { SelectFieldComponent } from './components/forms/select-field/select-field.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TagsFieldComponent } from './components/forms/tags-field/tags-field.component';
import { FileUploadFieldComponent } from './components/forms/file-upload-field/file-upload-field.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FileSizePipe } from './pipes/file-size.pipe';
import { ControlValidationMessageComponent } from './components/forms/control-validation-message/control-validation-message.component';
import { AppFramePageComponent } from '@core/components/app-frame-page/app-frame-page.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { SafePipe } from '@core/pipes/safe.pipe';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { TimeFormatPipe } from './pipes/time-format.pipe';
import { IfBreakpointDirective } from './directives/if-breakpoint.directive';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RouteCardComponent } from './components/route-card/route-card.component';
import { RouteCardGridComponent } from './components/route-card-grid/route-card-grid.component';
import { PageFrameComponent } from './components/page-frame/page-frame.component';
import { ConfirmModalButtonComponent } from './components/confirm-modal-button/confirm-modal-button.component';
import { ToggleFieldComponent } from './components/forms/toggle-field/toggle-field.component';
import { DropdownSelectFieldComponent } from './components/forms/dropdown-select-field/dropdown-select-field.component';
import { GlobalModalComponent } from './components/global-modal/global-modal.component';
import { TypeAheadFieldComponent } from './components/forms/type-ahead-field/type-ahead-field.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { LandingPageFrameComponent } from './components/landing-page-frame/landing-page-frame.component';
import { NotificationButtonComponent } from './components/notification-button/notification-button.component';
import { MarkdownEditorComponent } from './components/markdown-editor/markdown-editor.component';
import { MarkdownModule } from 'ngx-markdown';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { MarkdownViewerComponent } from './components/markdown-viewer/markdown-viewer.component';
import { VerticalMultistepFormDirective } from './directives/multistep-form/vertical-multistep-form.directive';
import { FormStepDirective } from './directives/multistep-form/form-step.directive';
import { UploadFieldComponent } from './components/form-controls/upload-field/upload-field.component';
import { GlobalToastComponent } from './components/global-toast/global-toast.component';
import { MultiSelectFieldComponent } from '@core/components/form-controls/multi-select-field/multi-select-field.component';
import { AssignLearningMaterialModalButtonComponent } from './components/assign-learning-material-modal-button/assign-learning-material-modal-button.component';
import { UserSelectFieldComponent } from '@core/components/user-select-field/user-select-field.component';
import { SpaceWordsPipe } from './pipes/space-words.pipe';
import { TechnologyTagsFieldComponent } from '@core/components/forms/technology-tags-field/technology-tags-field.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { IconComponent } from './components/icon/icon.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Office365ViewerComponent } from './components/office365-viewer/office365-viewer.component';
import { FileExplorerComponent } from '@core/components/file-explorer/file-explorer.component';
import { TruncatedPipe } from './pipes/truncated.pipe';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { PercentCircleComponent } from './components/percent-circle/percent-circle.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SearchBarInputComponent } from './components/search-bar-input/search-bar-input.component';
import { VideoSelectorComponent } from './components/video-selector/video-selector.component';
import { TypeAheadInputFieldComponent } from './components/form-controls/type-ahead-input-field/type-ahead-input-field.component';
import { CohortSelectFieldComponent } from './components/cohort-select-field/cohort-select-field.component';
import { DashboardGridComponent } from './components/dashboard-grid/dashboard-grid.component';
import { KtdGridModule } from '@katoid/angular-grid-layout';
import { WidgetsModule } from '@app/widgets/widgets.module';
import { AddChatButtonComponent } from '@core/components/add-chat-button/add-chat-button.component';
import { DeEnumeratePipe } from './pipes/deEnumerate.pipe';
import { SortableDirective } from './directives/sortable.directive';
import { VideoUploadFormComponent } from '@app/api/video/components/video-upload-form/video-upload-form.component';
import { VideoUploadModalComponent } from './components/video-upload-modal/video-upload-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MarkDownPdfViewerComponent } from './components/mark-down-pdf-viewer/mark-down-pdf-viewer.component';
import { QuizQuestionsStepperComponent } from './components/quiz-question-stepper/quiz-questions-stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ClickEventDirective } from './directives/click-event.directive';
import { StripHtmlTagsPipe } from './pipes/sanitize-html.pipe';
import {
  ExtractRavenFooterTextPipe,
  RemoveRavenFooterTextPipe,
} from './pipes/raven-integration.pipe';

@NgModule({
  declarations: [
    StringResourceComponent,
    StringResourcePipe,
    NavBarComponent,
    SideBarComponent,
    TextFieldComponent,
    SelectFieldComponent,
    TagsFieldComponent,
    FileUploadFieldComponent,
    FileSizePipe,
    ControlValidationMessageComponent,
    AppFramePageComponent,
    VideoPlayerComponent,
    SafePipe,
    ClickStopPropagationDirective,
    TimeFormatPipe,
    IfBreakpointDirective,
    RouteCardComponent,
    RouteCardGridComponent,
    PageFrameComponent,
    ConfirmModalButtonComponent,
    ToggleFieldComponent,
    DropdownSelectFieldComponent,
    GlobalModalComponent,
    TypeAheadFieldComponent,
    AutofocusDirective,
    LandingPageFrameComponent,
    NotificationButtonComponent,
    MarkdownEditorComponent,
    MarkdownViewerComponent,
    VerticalMultistepFormDirective,
    FormStepDirective,
    UploadFieldComponent,
    GlobalToastComponent,
    MultiSelectFieldComponent,
    AssignLearningMaterialModalButtonComponent,
    UserSelectFieldComponent,
    SpaceWordsPipe,
    TechnologyTagsFieldComponent,
    IconComponent,
    Office365ViewerComponent,
    FileExplorerComponent,
    TruncatedPipe,
    SpinnerComponent,
    PercentCircleComponent,
    SearchBarInputComponent,
    TypeAheadInputFieldComponent,
    VideoSelectorComponent,
    CohortSelectFieldComponent,
    DashboardGridComponent,
    AddChatButtonComponent,
    DeEnumeratePipe,
    SortableDirective,
    VideoUploadFormComponent,
    VideoUploadModalComponent,
    MarkDownPdfViewerComponent,
    QuizQuestionsStepperComponent,
    ClickEventDirective,
    StripHtmlTagsPipe,
    RemoveRavenFooterTextPipe,
    ExtractRavenFooterTextPipe,
  ],
  exports: [
    StringResourceComponent,
    StringResourcePipe,
    NavBarComponent,
    SideBarComponent,
    TextFieldComponent,
    TagsFieldComponent,
    FileUploadFieldComponent,
    AppFramePageComponent,
    VideoPlayerComponent,
    IfBreakpointDirective,
    RouteCardComponent,
    PageFrameComponent,
    RouteCardGridComponent,
    ToggleFieldComponent,
    SelectFieldComponent,
    DropdownSelectFieldComponent,
    TypeAheadFieldComponent,
    AutofocusDirective,
    LandingPageFrameComponent,
    MarkdownEditorComponent,
    ControlValidationMessageComponent,
    MarkdownViewerComponent,
    VerticalMultistepFormDirective,
    FormStepDirective,
    SafePipe,
    UploadFieldComponent,
    ClickStopPropagationDirective,
    GlobalToastComponent,
    MultiSelectFieldComponent,
    AssignLearningMaterialModalButtonComponent,
    SpaceWordsPipe,
    TechnologyTagsFieldComponent,
    AngularSvgIconModule,
    IconComponent,
    DragDropModule,
    FileSizePipe,
    Office365ViewerComponent,
    FileExplorerComponent,
    TruncatedPipe,
    SpinnerComponent,
    PercentCircleComponent,
    SearchBarInputComponent,
    AddChatButtonComponent,
    UserSelectFieldComponent,
    TypeAheadInputFieldComponent,
    CohortSelectFieldComponent,
    AddChatButtonComponent,
    UserSelectFieldComponent,
    TypeAheadInputFieldComponent,
    VideoSelectorComponent,
    CohortSelectFieldComponent,
    AddChatButtonComponent,
    UserSelectFieldComponent,
    TypeAheadInputFieldComponent,
    DashboardGridComponent,
    DeEnumeratePipe,
    SortableDirective,
    VideoUploadFormComponent,
    QuizQuestionsStepperComponent,
    ClickEventDirective,
    StripHtmlTagsPipe,
    RemoveRavenFooterTextPipe,
    ExtractRavenFooterTextPipe,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    NgbTooltip,
    NgOptimizedImage,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownItem,
    NgbDropdownMenu,
    ReactiveFormsModule,
    NgxFileDropModule,
    NgbProgressbar,
    FormsModule,
    ClipboardModule,
    NgbDropdownAnchor,
    MarkdownModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    NgCircleProgressModule.forRoot({
      animationDuration: 300,
    }),
    PdfViewerModule,
    CodemirrorModule,
    NgbToast,
    DragDropModule,
    KtdGridModule,
    WidgetsModule,
    NgbPagination,
    CdkStepperModule,
  ],
})
export class CoreModule {}
