<div class="p-3 h-100 d-flex sidebar-div">
  <div class="d-flex flex-column align-items-center p-0 m-0 h-100 bg-white border rounded-3 overflow-hidden z-front">
    <button *ngFor="let sideBarItem of items; let i=index"
            class="btn btn-link sidebar-item m-0 rounded-0 w-100 text-decoration-none text-black"
            [routerLink]="[sideBarItem.routerLink]"
            [ngClass]="{ 'top-rounded-border': i === 0 }"
            routerLinkActive="active-sidebar-item"
            placement="right"
            [ngbTooltip]="sideBarItem.label"
            [disableTooltip]="sidebarExpanded">
  <span class="d-flex flex-row justify-content-between">
    <span [ngClass]="{ 'sidebar-expanded': sidebarExpanded }" class="my-2 text-start sidebar-text">
      {{sideBarItem.label}}
    </span>
    <span [ngClass]="[ 'bi', 'mx-3 my-2', sideBarItem.icon, 'text-muted' ]"></span>
  </span>
    </button>
  </div>
  <div class="flex-shrink-1 expand-btn-div">
    <button class="btn btn-sm btn-light mt-4 rounded border border-start-0 rounded-start-0 py-2" (click)="toggleSidebar()">
      <div class="chevron-btn bi bi-chevron-right fw-bolder text-primary" [ngClass]="{ 'transform-rotate-180': sidebarExpanded }"></div>
    </button>
  </div>
</div>
