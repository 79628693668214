import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistrationService } from '@app/api/user/services/registration.service';
import { CustomValidators } from '@core/validation/custom-validators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.sass']
})
export class RegistrationPageComponent {

  registering = false;
  success = false;

  hasPromoCode = false;
  registrationForm: FormGroup;

  errorMessage = '';

  constructor(fb: FormBuilder, private registrationService: RegistrationService, private activatedRoute: ActivatedRoute) {

    this.registrationForm = fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [
        Validators.email,
        Validators.required
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(128),
        CustomValidators.password
      ]],
      confirmPassword: ['', Validators.required],
      promoCode: ['']
    });

    const passwordControl = this.registrationForm.get('password');
    const confirmPasswordControl = this.registrationForm.get('confirmPassword');

    confirmPasswordControl?.addValidators([
      CustomValidators.matches(passwordControl)
    ]);

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['promo']) {
        this.hasPromoCode = !!params['promo'];
        this.registrationForm.patchValue({
          promoCode: params['promo']
        });
      }

      if (params['firstName']) {
        this.registrationForm.patchValue({
          firstName: params['firstName'],
        });
      }

      if (params['lastName']) {
        this.registrationForm.patchValue({
          lastName: params['lastName'],
        });
      }

      if (params['email']) {
        this.registrationForm.patchValue({
          email: params['email'],
        });
      }

    });

  }

  registerUser() {
    this.registering = true;
    this.registrationService.registerUser(this.registrationForm.value).subscribe({
      next: _data => {
        this.registering = false;
        this.success = true;
      },
      error: error => {
        this.registering = false;

        this.errorMessage = error.error.message;
      },
      complete: () => {
        this.registering = false;
      }
    });

  }

}
