<div class="container" *ngIf="!loading && course && courseActivity; else loader">

  <div class="my-5 pt-3 flex-center flex-column">
    <div class="mb-3">
      <app-percent-circle [percent]="courseActivity.progress"></app-percent-circle>
    </div>
    <ng-container *ngIf="!isRavenLesson;else ravenLesson">
      <div class="text-center">
        <div class="text-muted small">
          Course
        </div>
        <h3 class="text-center">
          {{ course.title }}
        </h3>
      </div>
      <div class="my-2 flex-center gap-1">
      <span *ngFor="let tag of course.technologies" class="badge rounded-pill small bg-info text-white">
        {{ tag }}
      </span>
      </div>
      <div
        [innerHTML]="course.description | markdown" class="my-2 text-muted">
      </div>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-12 col-md-8 offset-md-2">
      <div class="card-body">

        <div class="list-group">

          <button *ngFor="let moduleActivity of courseActivity.linkedActivities"
                  [routerLink]="[ './module', moduleActivity.id ]"
                  class="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                  type="button">

            <div class="d-flex align-items-center">
              <span class="bi bi-folder-fill text-info fs-2 mx-3"></span>

              <div class="my-3">
                <div class="text-muted small">
                  Module
                </div>
                <div class="fw-bold">
                  {{ moduleActivity.order + 1 }}. {{ moduleActivity.title }}
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <div class="mx-2">
                <app-percent-circle [percent]="moduleActivity.progress" size="xxs"></app-percent-circle>
              </div>
              <span class="bi bi-chevron-right text-primary mx-3 fs-5"></span>
            </div>

          </button>

        </div>

      </div>
    </div>

  </div>
</div>

<ng-template #loader>
  <app-activity-skeleton-structure></app-activity-skeleton-structure>
</ng-template>

<ng-template #ravenLesson>

  <div *ngIf="course" class="row justify-content-center">
    <div class="col-12 col-lg-10">
      <div class="mb-4">
        <h1 class="display-6 fw-bold mb-3 text-center ">{{ course.title }}</h1>
        <div class="d-flex flex-wrap gap-2 mb-4 justify-content-center">
          <span *ngFor="let tag of course.technologies" class="badge bg-secondary px-3 py-2">{{ tag }}</span>
        </div>
      </div>
      <div class="card mb-4 scrollable-content">
        <div class="card-body p-4">
          <h2 class="fs-4 fw-semibold mb-3">{{ course.title }}</h2>
          <p
            [innerHTML]="course.description | removeRavenFooterText | markdown" class="h-25">
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="!courseActivity?.completed">
      <div class="my-3 d-flex justify-content-center">
        <a
          [href]="course.sourceUrl"
          appClickEvent class="btn btn btn-outline-primary" target="_blank">
          Start Course <span class="bi bi-box-arrow-up-right"></span>
        </a>
      </div>
    </div>
    <footer
      class="text-center text-muted small">
      <p>{{ course.description | extractRavenFooterText }}</p>
    </footer>
  </div>
</ng-template>
