<div class="container py-8">
  <div *ngIf="!showAnswers" class="mb-4 text-center">
    <p class="text-muted">{{ quiz.description }}</p>
    <h3 class="mt-4">Score:</h3>
    <div *ngIf="!isEditingScore; else editScoreTemplate" class="d-flex justify-content-center my-3">
      <app-percent-circle [percent]="submission?.score || 0" class="my-3"></app-percent-circle>
    </div>
    <div *ngIf="updateMessage" class="alert alert-info my-3">{{ updateMessage }}</div>
    <ng-template #editScoreTemplate>
      <form (ngSubmit)="submitScore()" >
        <div class="form-inline d-flex justify-content-center">
          <div class="input-group mb-3" style="max-width: 300px; width: 100%;">
            <input
              type="number"
              class="form-control"
              [ngModel]="updatedScore"
              (ngModelChange)="onScoreChange($event)"
              name="updatedScore"
              required
              min="0"
              max="100"
              maxlength="3"
              step="0.01"
              placeholder=""
              style="flex: 1;"
            />
            <button
              type="submit" class="btn btn-primary">Update</button>
            <button type="button" class="btn btn-outline-secondary ms-2" (click)="cancelEditScore()">Cancel</button>
          </div>
        </div>
        <div *ngIf="scoreIsInvalid" class="col-12 mb-2 animate__animated animate__headShake animate__fast">
            <span class="text-danger">
              Score must be between 0 and 100
            </span>
        </div>
      </form>
    </ng-template>
    <div class="d-flex justify-content-center mt-3 my-3">
      <ng-container *ngIf="submission?.graded && userRole === 'Admin'">
        <button class="btn btn-outline-primary me-2" (click)="startEditScore()" *ngIf="!isEditingScore">
          Edit Score
        </button>
      </ng-container>
      <button (click)="showAnswers = !showAnswers" class="btn btn-primary">
        {{ showAnswers ? 'Hide' : 'Show' }} Answers
      </button>
    </div>
  </div>
  <div *ngIf="showAnswers" class="row my-4">

    <app-quiz-question-stepper [orientation]="'vertical'">
      <cdk-step
        *ngFor="let submission of questionSubmission;index as index"
        [state]="isCorrect(submission) ? 'correct':'incorrect' "
        class="my-4 cdk-step">
        <p> {{ index + 1 }} Question: {{ submission.question.text }} </p>

        <div *ngIf="submission.question.type ==='MULTIPLE_CHOICE'">
          <div *ngFor=" let choice of submission.question.choices;index as i">
            <div class="d-flex flex-row gap-1 mx-4">
              <i
                [ngClass]="getCorrectAnswer(submission).includes(choice.id?.toString()??'') ? 'correct-answer-circle' : 'answer-circle'"></i>
              <p>Answer {{ i + 1 }} - {{ choice.choiceText }}</p>
            </div>
          </div>
          <ng-container [ngTemplateOutlet]="explanation"></ng-container>
        </div>
        <div *ngIf="submission.question.type ==='TRUE_FALSE'">
          <div *ngFor="let i of [0,1]">
            <div class="d-flex flex-row gap-1 mx-4">
              <i
                [ngClass]="getCorrectAnswer(submission).includes(i === 0 ? 'True' : 'False') ? 'correct-answer-circle' : 'answer-circle'"></i>
              <p>Answer {{ i + 1 }} - {{ i === 0 ? 'True' : 'False' }}</p>
            </div>
          </div>
          <ng-container [ngTemplateOutlet]="explanation"></ng-container>
        </div>
        <div *ngIf="submission.question.type ==='SHORT_ANSWER'">
          <div class="d-flex flex-row gap-1 mx-4">
            <i
              [ngClass]="getCorrectAnswer(submission).includes(submission.answer!) ? 'correct-answer-circle' : 'answer-circle'"></i>
            <p>Answer - {{ submission.question.correctAnswer?.correctAnswer }}</p>
          </div>
          <ng-container [ngTemplateOutlet]="explanation"></ng-container>
        </div>

        <div *ngIf="submission.question.type ==='FILL_IN_THE_BLANK'">
          <div class="d-flex flex-row gap-1 mx-4">
            <i
              [ngClass]="getCorrectAnswer(submission).includes(sanitizeFitbAnswer(submission.answer!)) ? 'correct-answer-circle' : 'answer-circle'"></i>
            <p>Answer - {{ sanitizeFitbAnswer(submission.question.fillInTheBlankText) }}</p>
          </div>
          <ng-container [ngTemplateOutlet]="explanation"></ng-container>
        </div>


        <ng-template #explanation>
          <div *ngIf="getExplanation(submission) as explanation" class="alert alert-info my-3">
            <h6><span class="bi bi-info-circle-fill"></span> Explanation</h6>
            <div [innerHTML]="explanation | markdown"></div>
          </div>
        </ng-template>

      </cdk-step>
    </app-quiz-question-stepper>


  </div>
</div>
