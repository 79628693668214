<form [formGroup]="loginFormGroup" (submit)="submitLogin()" class="container-fluid">
  <div class="row">
    <div class="col-12 mb-2">
      <div class="form-floating">
        <input type="text"
               class="form-control"
               id="email"
               [placeholder]="'Email' | stringResource"
               formControlName="email">
        <label for="email">
          {{ 'Email' | stringResource }}
        </label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mb-2">
      <div class="form-floating">
        <input type="password"
               class="form-control"
               id="password"
               [placeholder]="'Password' | stringResource" formControlName="password">
        <label for="password">
          {{ 'Password' | stringResource }}
        </label>
      </div>
    </div>
  </div>
  <div class="row">
    <div *ngIf="loginError" class="col-12 mb-2 animate__animated animate__headShake animate__fast">
      <span class="text-danger">{{loginError}}</span>
      <div *ngIf="loginError.includes('email')">
        <button *ngIf="!resentEmailVerification" class="btn btn-link p-0" (click)="resendEmailVerification()">Resend verification</button>
        <span *ngIf="resentEmailVerification" class="text-muted">Email verification sent! <span class="bi-check-circle"></span></span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-3">
      <button type="submit"
              [disabled]="loginFormGroup.disabled"
              class="btn btn-primary w-100">{{ 'Sign In' | stringResource }} <span class="bi bi-box-arrow-in-right"></span></button>
    </div>
  </div>
</form>
