import { LearningActivity } from '@app/api/models/learning-activity.model';
import { StudentService } from '@app/api/student/services/student.service';
import { Student } from './../../../../student/model/student.model';
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { UserService } from '@app/api/user/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MultiSelectOption } from '@app/core/components/form-controls/multi-select-field/multi-select-field.component';
import { GlobalModalService } from '@app/core/services/global-modal.service';
import { catchError, map, of } from 'rxjs';
import { GlobalToastService } from '@app/core/services/global-toast.service';
import { CohortApiService } from '@app/api/training/services/cohort-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { UserNotificationService } from '@app/api/notifications/services/user-notification.service';

@Component({
  selector: 'app-view-student',
  templateUrl: './view-student.component.html',
  styleUrls: ['./view-student.component.sass']
})
export class ViewStudentComponent {

  student?: Student;
  profilePictureUrl?: string;
  learningActivities: LearningActivity[] = [];
  selectedStudentPhases: string[] = [];
  selectedStudentCohorts: number[] = [];
  allPhases: MultiSelectOption[] = [];
  allCohorts: MultiSelectOption[] = [];

  @ViewChild('updatePhasesModal') updatePhasesModal!: TemplateRef<any>;
  @ViewChild('updateCohortsModal') updateCohortsModal!: TemplateRef<any>;
  @ViewChild('learningActivitiesModal') learningActivitiesModal!: TemplateRef<any>;

  constructor(private studentService: StudentService,
    private cohortApi: CohortApiService,
    private userService: UserService,
    private toastService: GlobalToastService,
    private learningActivityService: LearningActivityService,
    private router: Router,
    private ngModal: NgbModal,
    private modalService: GlobalModalService,
    private route: ActivatedRoute,
    private userNotificationService: UserNotificationService){
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.studentService.getStudentById(id).subscribe({
        next: student => {
          this.student = student;
        }
      });

      this.userService.fetchProfilePicture(id).subscribe({
        next: url => {
          this.profilePictureUrl = url;
        }
      });

      this.cohortApi.getAllCohorts('').subscribe(cohorts => {
        this.allCohorts = cohorts.content.map(cohort => ({
          label: cohort.name,
          value: cohort.id
        }));
      });
    });
  }

  get currentStudentPhases() {
    return [...(this.student?.phases || [])];
  }

  get currentStudentCohorts() {
    return [...(this.student?.cohorts || [])];
  }

  openUpdateCohortsModal() {
    const search = '';
    this.cohortApi.getAllCohorts(search, {
      sort: 'startDate,desc',
    })
      .subscribe(cohorts => {
        this.allCohorts = cohorts.content.map(cohort => ({
          label: cohort.name,
          value: cohort.id
        }));
        this.selectedStudentCohorts = this.currentStudentCohorts || [];
        const modal = this.modalService.alert({
          title: 'Update Cohorts',
          content: this.updateCohortsModal,
          type: 'info',
          showCancelButton: true,
          okButtonText: 'Update'
        });

        modal.closed.subscribe({
          next: () => {
            this.updateStudentCohorts(this.selectedStudentCohorts);
          }
        });
      });
  }

  getCohortName(cohortId: number): string {
    const cohort = this.allCohorts.find(cohort => cohort.value === cohortId);
    return cohort ? cohort.label : '';
  }

  updateStudentCohorts(cohorts: number[]) {
    const student: Student = {
      id: this.student?.id,
      email: this.student?.email || '',
      firstName: this.student?.firstName || '',
      lastName: this.student?.lastName || '',
      cohorts: cohorts,
      phases: this.student?.phases || [],
      learningActivities: this.student?.learningActivities || []
    };

    this.studentService.updateStudent(student).subscribe({
      next: (student) => {
        this.modalService.alert({
          title: 'Cohorts updated',
          content: `Cohorts updated for ${this.student?.email}`,
          type: 'success'
        });

        this.student = student;
      }
    });
  }

  openLearningActivitiesModal() {
    if(this.student?.id){
      this.ngModal.open(this.learningActivitiesModal, {
        size: 'lg'
      });
      this.learningActivityService.getLearningActivitiesForStudent(this.student?.id)
        .subscribe({
          next: (activities) => {
            this.learningActivities = activities.content;
          },
        });
    }
  }

  openUpdatePhasesModal() {
    this.studentService.getAllPhases()
      .pipe(map(phases => phases.map(phase => {
        return {
          label: phase,
          value: phase,
        };
      } ))
      ).subscribe({
        next: phases => {
          this.allPhases = phases;
          this.selectedStudentPhases = this.currentStudentPhases || [];
          const modal = this.modalService.alert({
            title: 'Update Phases',
            content: this.updatePhasesModal,
            type: 'info',
            showCancelButton: true,
            okButtonText: 'Update'
          });
          modal.closed.subscribe({
            next: () => {
              this.updateStudentPhases(this.selectedStudentPhases);
            }
          });
        }
      });
  }

  updateStudentPhases(phases: string[]) {
    const student: Student = {
      id:this.student?.id,
      email: this.student?.email || '',
      firstName: this.student?.firstName || '',
      lastName: this.student?.lastName || '',
      phases: phases,
      learningActivities: this.student?.learningActivities || [],
      cohorts: this.student?.cohorts || []
    };

    this.studentService.updateStudent(student).subscribe({
      next: (student) => {
        this.modalService.alert({
          title: 'Phases updated',
          content: `Phases updated for ${this.student?.email}`,
          type: 'success'
        });
        this.userNotificationService.updateNotifications();
        this.student = student;
      }
    });
  }

  openDeleteStudentConfirm() {
    this.modalService.confirmDelete(
      'Are you sure you want to delete this student? This cannot be undone.',
      this.student?.firstName)
      .subscribe({
        next: (result) => {
          if (result) {
            this.deleteStudent();
          }
        }
      });
  }

  deleteStudent() {
    if(this.student?.id){
      this.studentService.deleteStudent(this.student?.id)
        .pipe(catchError((_err) => {
          this.toastService.showDangerToast('Error deleting student.');
          return of(null);
        }))
        .subscribe({
          next: (student) => {
            if (!student) return;
            this.toastService.showSuccessToast('student successfully deleted!');
            this.router.navigate(['/training/students']);
          }
        });
    }
  }
}
