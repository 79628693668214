<!-- <div class="row">
  <div class="col-5 col-lg-4 border-end" *ngIf="module; else spinner">
  </div>
  <div class="col-7 col-lg-8">
    <router-outlet></router-outlet>
  </div>
</div>


<ng-template #spinner>
  <div class="flex-center">
    <app-spinner></app-spinner>
  </div>
</ng-template> -->

<ng-container *ngIf="module; else spinner">
  <div class="my-3 flex-center">
    <h3 class="text-center">{{ module.title }}</h3>
  </div>

  <div class="my-3">
    <div class="text-muted text-center pb-2 mb-3 border-bottom">
      <p>{{ module.description }}</p>
    </div>

    <div class="my-3">
      <h4 class="text-center">Lessons and Assignments</h4>
      <div *ngIf="module.lessons; else noMaterials">
        <ul class="list-group">
          <li *ngFor="let material of module.lessons" class="list-group-item">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h5>{{ material.title }}</h5>
              </div>
              <div>
              </div>
            </div>
      
            <div *ngIf="material.assignments" class="mt-3">
              <ul class="list-group">
                <li *ngFor="let assignment of material.assignments" class="list-group-item">
                  <div class="d-flex justify-content-between align-items-center">
                    <span>{{ assignment.title }}</span>
                    <button 
                      type="button" 
                      class="btn btn-outline-primary"
                      (click)="previewActivity(material)">
                      Preview
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      
      
      
      <ng-template #noMaterials>
        <p class="text-muted text-center">No learning materials available for this module.</p>
      </ng-template>
    </div>
  </div>
</ng-container>

<ng-template #spinner>
  <div class="my-3 text-center">
    <app-spinner></app-spinner>
  </div>
</ng-template>
