import { Component, OnInit } from '@angular/core';
import { UserSettingsService } from '@app/api/user/services/user-settings.service';
import { UserSetting } from '@app/api/user/models/user-settings.model';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.sass']
})
export class UserSettingsComponent implements OnInit {

  settings: UserSetting[] = [];
  loading = false;
  settingsForm: FormGroup;

  constructor(private userSettingsService: UserSettingsService, private fb: FormBuilder) {
    this.settingsForm = fb.group({
      markdownEditorDefault: [true],
      darkMode: [false],
      allowEmailNotifications: [true],
      notifyEmailNewAssignment: [true],
      notifyEmailNewFeedback: [true],
      notifyEmailChatMessage: [true],
      notifyEmailDiscussionBoardActivity: [true],
      notifyEmailGradingActivity: [true],
      notifyEmailStudentSubmission: [true],
      notifyEmailStudentPhaseUpdate: [true],
    });

    this.listenForSettingChange('markdownEditorDefault');
    this.listenForSettingChange('darkMode');
    this.listenForSettingChange('allowEmailNotifications');
    this.listenForSettingChange('notifyEmailNewAssignment');
    this.listenForSettingChange('notifyEmailNewFeedback');
    this.listenForSettingChange('notifyEmailChatMessage');
    this.listenForSettingChange('notifyEmailDiscussionBoardActivity');
    this.listenForSettingChange('notifyEmailGradingActivity');
    this.listenForSettingChange('notifyEmailStudentSubmission');
    this.listenForSettingChange('notifyEmailStudentPhaseUpdate');

    this.settingsForm.get('allowEmailNotifications')?.valueChanges.subscribe((value) => {
      if (!value) {
        this.settingsForm.get('notifyEmailNewAssignment')?.disable();
        this.settingsForm.get('notifyEmailNewFeedback')?.disable();
        this.settingsForm.get('notifyEmailChatMessage')?.disable();
        this.settingsForm.get('notifyEmailDiscussionBoardActivity')?.disable();
        this.settingsForm.get('notifyEmailGradingActivity')?.disable();
        this.settingsForm.get('notifyEmailStudentSubmission')?.disable();
        this.settingsForm.get('notifyEmailStudentPhaseUpdate')?.disable();
      } else {
        this.settingsForm.get('notifyEmailNewAssignment')?.enable();
        this.settingsForm.get('notifyEmailNewFeedback')?.enable();
        this.settingsForm.get('notifyEmailChatMessage')?.enable();
        this.settingsForm.get('notifyEmailDiscussionBoardActivity')?.enable();
        this.settingsForm.get('notifyEmailGradingActivity')?.enable();
        this.settingsForm.get('notifyEmailStudentSubmission')?.enable();
        this.settingsForm.get('notifyEmailStudentPhaseUpdate')?.enable();
      }
    });

  }

  listenForSettingChange(key: string) {
    const kabobCaseKey = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    this.settingsForm.get(key)?.valueChanges.subscribe((value) => {
      this.updateSetting(kabobCaseKey, value.toString());
    });
  }

  ngOnInit(): void {
    this.retrieveUserSettings();
  }

  syncFormGroupWithSettings() {
    this.settingsForm.setValue({
      markdownEditorDefault: this.getSettingAsBoolean('markdownEditorDefault'),
      darkMode: this.getSettingAsBoolean('darkMode'),
      allowEmailNotifications: this.getSettingAsBoolean('allowEmailNotifications'),
      notifyEmailNewAssignment: this.getSettingAsBoolean('notifyEmailNewAssignment'),
      notifyEmailNewFeedback: this.getSettingAsBoolean('notifyEmailNewFeedback'),
      notifyEmailChatMessage: this.getSettingAsBoolean('notifyEmailChatMessage'),
      notifyEmailDiscussionBoardActivity: this.getSettingAsBoolean('notifyEmailDiscussionBoardActivity'),
      notifyEmailGradingActivity: this.getSettingAsBoolean('notifyEmailGradingActivity'),
      notifyEmailStudentSubmission: this.getSettingAsBoolean('notifyEmailStudentSubmission'),
      notifyEmailStudentPhaseUpdate: this.getSettingAsBoolean('notifyEmailStudentPhaseUpdate'),
    });
  }

  retrieveUserSettings() {
    this.loading = true;
    this.userSettingsService.getUserSettings().subscribe((settings) => {
      this.settings = settings.settings;
      this.syncFormGroupWithSettings();
      this.loading = false;
    });
  }

  updateSetting(key: string, value: string | null) {
    this.loading = true;
    this.userSettingsService.updateUserSetting(key, value).subscribe((settings) => {
      this.settings = settings.settings;
      this.loading = false;
    });
  }

  resetSettings() {
    this.loading = true;
    this.userSettingsService.resetUserSettings().subscribe((settings) => {
      this.settings = settings.settings;
      this.syncFormGroupWithSettings();
      this.loading = false;
    });
  }

  getSetting(key: string) {
    // setting keys are always in kabob-case
    // convert from camelCase to kabob-case
    key = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    return this.settings.find(setting => setting.key === key);
  }

  getSettingAsBoolean(key: string) {
    const setting = this.getSetting(key);
    return setting ? setting.value === 'true' : false;
  }

}
