import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { SideBarItem } from '@core/components/side-bar/side-bar-item.interface';
import { SideBarService } from '@core/services/side-bar.service';
import { UserService } from '@app/api/user/services/user.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.sass']
})
export class SideBarComponent implements OnInit {

  @Input() sideBarItems: SideBarItem[] = [];
  _sideBarItems: SideBarItem[] = [];

  get items() {
    return this._sideBarItems;
  }

  set items(items: SideBarItem[]) {
    this._sideBarItems = items;
  }

  toggleSidebar() {
    this.sidebar.toggleSidebar();
  }

  constructor(private sidebar: SideBarService, private user: UserService, private el: ElementRef) {}

  ngOnInit() {
    this.items = this.sideBarItems.filter((item) => {
      return !item.roles;
    });

    this.user.getSelf().subscribe((user) => {
      this.items = this.sideBarItems.filter((item) => {
        const { roles } = user;
        if (item.roles) {
          return roles.some((role) => item.roles!.includes(role));
        }
        return true;
      });
    });

  }

  get sidebarExpanded() {
    return this.sidebar.expanded;
  }

}
