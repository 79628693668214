import { Component, OnInit } from '@angular/core';
import { Notification } from '@core/interfaces/notification.interface';
import { NotificationService } from '@core/services/notification.service';
import { UserNotificationService } from '@app/api/notifications/services/user-notification.service';
import { UserService } from '@app/api/user/services/user.service';
import { map } from 'rxjs';
import { Router } from '@angular/router';
import { MarkdownService } from 'ngx-markdown';

@Component({
  selector: 'app-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.sass']
})
export class NotificationButtonComponent implements OnInit {

  isOpen = false;

  unreadCount = 0;

  constructor(
    private notificationService: NotificationService,
    private userNotificationService: UserNotificationService,
    private userService: UserService,
    private router: Router,
    private markdown: MarkdownService
  ) {
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }
  closeDropdown(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const dropdownMenu = document.querySelector('.dropdown-menu');
    if (!dropdownMenu?.contains(target) && !target.closest('.btn-outline-primary')) {
      this.isOpen = false;
    }
  }

  ngOnInit() {
    this.loadUserNotifications();
    this.userNotificationService.notificationsUpdated.subscribe(() => {
      this.loadUserNotifications();
    });
  }

  loadUserNotifications() {
    this.userService.getSelf().subscribe(user => {
      this.userNotificationService.receiveUserNotifications(user.id)
        .pipe(
          map(notifications => {
            this.unreadCount = notifications.filter(notification => !notification.readAt).length; // Calculate unread count
            return notifications.map(notification => {
              return {
                id: notification.id,
                uniqueName: notification.id,
                label: notification.title,
                message: this.trimMessage(notification.message),
                type: 'info',
                closeable: true,
                action: () => {
                  this.router.navigate(['/notifications', notification.id]);
                }
              } as Notification;
            });
          })
        )
        .subscribe(notifications => {
          this.notificationService.clearAll();
          notifications.forEach(notification => this.notificationService.addNotification(notification));
        });
    });
  }

  get notifications(): Notification[] {
    return this.notificationService.notifications;
  }

  trimMessage(message: string) {
    const trimmedMessage = message.replace(/<[^>]*>?/gm, '');
    return trimmedMessage.length > 30 ? trimmedMessage.substring(0, 30) + '...' : trimmedMessage;
  }

  removeNotification(notification: Notification) {
    this.userNotificationService.deleteUserNotification(notification.uniqueName!).subscribe(() => {
      this.notificationService.deleteNotification(notification.id!);
    });
  }

}
