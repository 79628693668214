export interface BuildInfo {
  buildTime: string;
  buildNumber: string;
  commit: string;
  branch: string;
  environment: string;
}

const buildInfo: BuildInfo = {
  environment: 'dev',
  buildTime: '',
  buildNumber: '6',
  commit: '5690f16297c2f728c91bba9473dc79cf4fbe7e9c',
  branch: 'release-1.0.24'
};

export const environment = {
  production: false,
  clientUrl: 'https://app.dev.learn.smoothstack.com',
  apiUrl: 'https://api.dev.learn.smoothstack.com/api/v1',
  azureClientId: 'e9a55099-2a1f-49ac-834c-d57877cab455',
  azureTenantId: '7824f42c-45bd-47d8-8d15-275c536fa0a2',
  redirectUri: 'https://app.dev.learn.smoothstack.com/login',
  postLogoutRedirectUri: 'https://app.dev.learn.smoothstack.com/login',
  buildInfo: buildInfo
};
