import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NonNullableFormBuilder, Validators } from '@angular/forms';
import { VideoService } from '@app/api/video/services/video.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, forkJoin } from 'rxjs';
import { VideoUpdateForm } from '@core/components/video-update-form/video-update-form.interface';
import { VideoMetaData } from '@app/api/video/interfaces/video-meta-data.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';
import { GlobalToastService } from '@app/core/services/global-toast.service';
import { GlobalModalService } from '@app/core/services/global-modal.service';

@Component({
  selector: 'app-video-metadata-update-form',
  templateUrl: './video-update-form.component.html',
  styleUrls: ['./video-update-form.component.sass']
})
export class VideoUpdateFormComponent implements OnInit {
  @ViewChild('videoUpdateSuccess') successModel?: TemplateRef<any>;
  isLoading = true;
  isSubmitting = false;
  thumbnailUrl?: string;
  id = Number(this.route.snapshot.params['id']);
  form = this.fb.group<VideoUpdateForm>({
    title: this.fb.control('', [Validators.required]),
    description: this.fb.control('', [Validators.required]),
    tags: this.fb.control('', [Validators.required]),
    thumbnailFile: this.fb.control(null,)
  });

  constructor(
    private fb: NonNullableFormBuilder,
    private videoService: VideoService,
    private modalService: NgbModal,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: GlobalToastService,
    private globalModal: GlobalModalService) {

  }


  ngOnInit(): void {
    const id = Number(this.route.snapshot.params['id']);
    const metadataState = window.history.state['video'] as VideoMetaData;

    if (metadataState) {
      this.onLoaded(metadataState);
    } else {
      forkJoin([
        this.videoService.getVideoMetaData(Number(id)),
        this.videoService.getVideoThumbnail(id).pipe(catchError(() => [null]))
      ]).subscribe({
        next: ([metadata, thumbnail]) => {
          let thumbnailFile = null;
          let thumbnailUrl = null;
          if (thumbnail) {
            thumbnailFile = new File([thumbnail], metadata.title);
            thumbnailUrl = URL.createObjectURL(thumbnail);
          }

          this.onLoaded({
            ...metadata,
            thumbnailFile: thumbnailFile ?? metadata.thumbnailFile,
            thumbnailUrl: thumbnailUrl ?? metadata.thumbnailUrl
          });
        }
      });
    }

  }


  onLoaded(metadata: VideoMetaData) {
    this.form.patchValue({
      ...metadata,
    });

    this.thumbnailUrl = metadata.thumbnailUrl;
    this.isLoading = false;
  }


  submitForm() {
    this.isSubmitting = true;
    this.videoService.updateVideo(this.id, {
      title: this.form.value.title ?? '',
      description: this.form.value.description ?? '',
      tags: this.form.value.tags ?? '',
      thumbnailFile: this.form.value.thumbnailFile,
    }).subscribe(value => {

      this.isSubmitting = false;
      this.openSuccessModal();
      //update route state

    });
  }

  openSuccessModal() {
    this.modalService.open(this.successModel, { centered: true, backdrop: 'static', keyboard: false });
  }

  watchNow() {
    this.router.navigate(['videos', 'watch', this.id]).then(() => {
      this.modalService.dismissAll();
    });
  }

  navigateBacK() {
    this.modalService.dismissAll();
    this.location.back();
  }

  deleteVideo() {
    this.globalModal.confirm('Are you sure you want to delete this video?').subscribe((confirmed) => {
      if (confirmed) {
        this.videoService.deleteVideo(this.id).subscribe({
          next: (response) => {
            this.toastService.showSuccessToast('Video deleted successfully.');
            this.router.navigate(['/videos']).then(() => {
              console.log('Navigation to /videos successful.');
            });
            console.log('Video deleted', response);
          },
          error: (error) => {
            this.toastService.showDangerToast('Failed to delete the video.');
            console.error('Error deleting video', error);
          }
        });
      }
    });
  }  
}
