import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtmlTags',
})
export class StripHtmlTagsPipe implements PipeTransform {
  transform(value: string): string {
    return (
      value
        //&nbsp;
        .replace(/&nbsp;/g, ' ')
        //Remove HTML tags from description
        .replace(/<[^>]*>?/gm, '')
    );
  }
}
