import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistrationService } from '@app/api/user/services/registration.service';
import { CustomValidators } from '@core/validation/custom-validators';
import { ActivatedRoute } from '@angular/router';
import { RegisterAndEnrollTokenDetails } from '@app/api/user/models/register-and-enroll.model';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.sass']
})
export class RegistrationPageComponent {

  registering = false;
  success = false;

  hasPromoCode = false;
  registrationForm: FormGroup;

  loading = false;

  errorMessage = '';
  tokenDetails?: RegisterAndEnrollTokenDetails;

  constructor(fb: FormBuilder, private registrationService: RegistrationService, private activatedRoute: ActivatedRoute) {

    this.registrationForm = fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailDisabled: ['', Validators.required],
      email: ['', [
        Validators.email,
        Validators.required
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(128),
        CustomValidators.password
      ]],
      confirmPassword: ['', Validators.required],
      enrollmentToken: ['']
    });

    const passwordControl = this.registrationForm.get('password');
    const confirmPasswordControl = this.registrationForm.get('confirmPassword');

    confirmPasswordControl?.addValidators([
      CustomValidators.matches(passwordControl)
    ]);

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['token']) {
        this.loadTokenDetails(params['token']);
        this.registrationForm.patchValue({
          enrollmentToken: params['token'],
        });
      }
    });

  }

  loadTokenDetails(token: string) {
    this.loading = true;
    this.registrationService.getTokenDetails(token).subscribe({
      next: tokenDetails => {
        this.tokenDetails = tokenDetails;
        this.loading = false;

        this.registrationForm.patchValue({
          emailDisabled: this.tokenDetails.email,
          email: this.tokenDetails.email,
          firstName: this.tokenDetails.firstName,
          lastName: this.tokenDetails.lastName
        });

        this.registrationForm.get('emailDisabled')?.disable();
      }
    });
  }

  registerUser() {
    this.registering = true;
    this.registrationService.registerUser(this.registrationForm.value).subscribe({
      next: _data => {
        this.registering = false;
        this.success = true;
      },
      error: error => {
        this.registering = false;

        this.errorMessage = error.error.message;
      },
      complete: () => {
        this.registering = false;
      }
    });

  }

}
