<div *ngIf="!loading  && activity; else loader" class="container">
  <div *ngIf="activity && activity.parentModule && showNavigation" class="container">
    <div class="my-2">
      <button class="btn btn-outline-primary border-0" [routerLink]="learningRouteData.getModulePathArray()">
        <div class="d-flex align-items-start">
          <span class="bi bi-chevron-left me-2 flex-shrink-1"></span>
          <div class="text-start">
            <div class="small">
              <span class="fw-bold">Back to Module</span>
            </div>
            {{ activity.parentModule.title }}
          </div>
        </div>
      </button>
    </div>
  </div>
  <app-lesson-view
    *ngIf="activity.type === 'LESSON' && lesson"
    [learningActivity]="activity" [lessonId]="lesson.id!">
  </app-lesson-view>

  <app-quiz-activity-view
    *ngIf="activity.type === 'QUIZ'"
    [learningActivityId]="activity.id"/>
</div>

<ng-template #loader>
  <app-activity-skeleton-structure structureType="lesson"></app-activity-skeleton-structure>
</ng-template>
