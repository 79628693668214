import { Component, OnDestroy, OnInit } from '@angular/core';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { ModuleData } from '@app/api/models/learning-material-data.model';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { Subscription, switchMap } from 'rxjs';
import { ModuleApiService } from '@app/api/module/services/module-api.service';
import { ActivatedRoute } from '@angular/router';
import { LearningRouteDataService } from '@app/learning/services/learning-route-data.service';

@Component({
  selector: 'app-module-activity-view',
  templateUrl: './module-activity-view.component.html',
  styleUrls: ['./module-activity-view.component.sass'],
})
export class ModuleActivityViewComponent implements OnInit, OnDestroy {
  moduleActivityId?: number;
  moduleActivity?: LearningActivity;
  module?: ModuleData;

  learningActivitySubscription?: Subscription;

  loading = false;

  constructor(
    private route: ActivatedRoute,
    public learningRouteData: LearningRouteDataService,
    private learningActivityService: LearningActivityService,
    private moduleApi: ModuleApiService,
  ) {
    this.route.paramMap.subscribe((params) => {
      this.learningRouteData.updateRouteData(params);

      const moduleId = params.get('moduleId');

      if (moduleId) {
        this.moduleActivityId = Number(moduleId);
        this.loadModuleActivity(this.moduleActivityId);
      }
    });
  }

  ngOnInit() {
    if (this.moduleActivityId) {
      this.loadModuleActivity(this.moduleActivityId);
    }
  }

  loadModuleActivity(moduleActivityId: number, courseId?: number) {
    this.loading = true;

    if (this.learningActivitySubscription) {
      this.learningActivitySubscription.unsubscribe();
    }

    this.learningActivitySubscription = this.learningActivityService
      .getLearningActivity(moduleActivityId)
      .pipe(
        switchMap((moduleActivity) => {
          this.moduleActivity = moduleActivity;
          return this.moduleApi.getModuleById(moduleActivity.materialId);
        }),
      )
      .subscribe({
        next: (module) => {
          this.module = module;
          this.loading = false;
        },
      });
  }

  unloadModuleActivity() {
    this.moduleActivity = undefined;
    this.module = undefined;

    if (this.learningActivitySubscription) {
      this.learningActivitySubscription.unsubscribe();
    }
  }

  getRoutePath(activity: LearningActivity) {
    switch (activity.type) {
      case 'LESSON':
        return ['./lesson', activity.id];
      case 'QUIZ':
        return ['./quiz', activity.id];
      default:
        return ['./activity', activity.id];
    }
  }

  ngOnDestroy() {
    this.unloadModuleActivity();
  }
}
