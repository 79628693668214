<div *ngIf="!loading  && learningActivity; else loader" class="container">
  <div *ngIf="learningActivity.parentModule && showNavigation" class="container">
    <div class="my-2">
      <button [routerLink]="learningRouteData.getModulePathArray()" class="btn btn-outline-primary border-0">
        <div class="d-flex align-items-start">
          <span class="bi bi-chevron-left me-2 flex-shrink-1"></span>
          <div class="text-start">
            <div class="small">
              <span class="fw-bold">Back to Module</span>
            </div>
            {{ learningActivity.parentModule.title }}
          </div>
        </div>
      </button>
    </div>
  </div>
  <app-lesson-view
    *ngIf="learningActivity.type === 'LESSON'"
    [learningActivity]="learningActivity"
    [lessonId]="learningActivity.materialId!">
  </app-lesson-view>
  <app-assignment-view
    *ngIf=" learningActivity.type === 'ASSIGNMENT'"
    [assignmentId]="learningActivity.materialId!"
    [gradingView]="!userIsStudent && !assignedToUser"
    [learningActivity]="learningActivity">
  </app-assignment-view>
  <app-quiz-activity-view
    *ngIf=" learningActivity.type === 'QUIZ'"
    [learningActivityId]=" learningActivity.id! "
  ></app-quiz-activity-view>
</div>

<ng-template #loader>
  <app-activity-skeleton-structure structureType="assignment"></app-activity-skeleton-structure>
</ng-template>
