import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssignmentSubmissionPart, LearningActivitySubmission } from '@app/api/models/learning-activity.model';
import { UploadService } from '@core/services/upload.service';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { GlobalModalService } from '@core/services/global-modal.service';
import { AssignmentData, SubmissionType } from '@app/api/assignment/models/assignment-data.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, of } from 'rxjs';
import { GlobalToastService } from '@core/services/global-toast.service';
import { AppInfoService } from '@core/services/app-info.service';

@Component({
  selector: 'app-assignment-submission-form',
  templateUrl: './assignment-submission-form.component.html',
  styleUrls: ['./assignment-submission-form.component.sass']
})
export class AssignmentSubmissionFormComponent implements OnInit {

  @Input() learningActivityId = 0;
  @Input({ required: true }) assignment!: AssignmentData;
  @Input({ required: true }) submission!: LearningActivitySubmission;
  @Output() submissionChange = new EventEmitter<LearningActivitySubmission>();
  @Output() progressChange = new EventEmitter<number>();

  submissionTypes: SubmissionType[] = [];
  uploadedFiles: File[] = [];
  savingSubmission = false;
  linkSubmissions: string[] = [];
  linkText = '';
  urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].\S*$/i;

  loadingViewFileUrl = false;

  submissionForm?: FormGroup;

  constructor(
    private uploadService: UploadService,
    private modalService: GlobalModalService,
    private toastService: GlobalToastService,
    private fb: FormBuilder,
    private appInfo: AppInfoService,
    private learningActivityService: LearningActivityService) {
  }

  ngOnInit() {
    this.submissionTypes = this.assignment.submissionTypes;

    const submissionParts = this.submission.submissionParts.map(part => {
      return {
        [part.submissionType]: part
      };
    });

    this.submissionForm = this.fb.group({});
    this.submissionTypes.forEach(submissionType => {
      const isFileUpload = submissionType === 'FILE_UPLOAD';
      const isText = submissionType === 'TEXT';
      const isLink = submissionType === 'LINK';
      this.submissionForm?.addControl(submissionType, this.fb.group({
        id: [
          submissionParts.find(part => part[submissionType])?.[submissionType]?.id || null
        ],
        content: [
          submissionParts.find(part => part[submissionType])?.[submissionType]?.content || null,
          isFileUpload || isText || isLink ? Validators.required : []
        ],
        submitted: [
          submissionParts.find(part => part[submissionType])?.[submissionType]?.submitted || false
        ],
        submittedAt: [
          submissionParts.find(part => part[submissionType])?.[submissionType]?.submittedAt || null
        ],
        submittedBy: [
          submissionParts.find(part => part[submissionType])?.[submissionType]?.submittedBy || null
        ],
      }));
    });

    // Populate link submissions
    const linkSubmission = submissionParts.find(part => part['LINK'])?.['LINK'];
    if (linkSubmission && linkSubmission.content) {
      this.linkSubmissions = linkSubmission.content.split(' ');
    }
  }

  hasSubmissionType(submissionType: SubmissionType) {
    return this.submissionTypes.includes(submissionType);
  }

  saveFileSubmissionPart(submit = false) {

    const path = `learning-activities/${this.learningActivityId}/submissions/${this.submission.id}`;
    const fileName = `submission-${this.submission.id}-FILE_UPLOAD-${Date.now()}`;
    const overwrite = true;
    const publiclyAccessible = false;

    if (!submit) {
      this.modalService.confirm('Are you sure you want to remove this file?', {
        type: 'warning',
      }).subscribe({
        next: (confirmed) => {
          if (confirmed) {
            this.submissionForm?.get('FILE_UPLOAD.submitted')?.patchValue(submit);
          }
        }
      });
      return;
    }

    this.savingSubmission = true;

    if (!this.uploadedFiles.length) {
      this.savingSubmission = false;
      return;
    }

    const file = this.uploadedFiles[0];

    const extension = file.name.split('.').pop();

    const fullFileName = extension ? `${fileName}.${extension}` : fileName;

    this.uploadService.uploadFile(file, path, overwrite, publiclyAccessible, fullFileName).subscribe({
      next: (response) => {
        this.uploadedFiles = [];
        this.savingSubmission = false;
        this.submissionForm?.get('FILE_UPLOAD.content')?.patchValue(response.fileDownloadUri);
        this.submissionForm?.get('FILE_UPLOAD.content')?.updateValueAndValidity();
        this.submissionForm?.get('FILE_UPLOAD.submitted')?.patchValue(submit);
        this.submissionForm?.get('FILE_UPLOAD.submitted')?.updateValueAndValidity();

        this.saveSubmissionPart({
          id: this.submissionForm?.get('FILE_UPLOAD.id')?.value,
          content: this.submissionForm?.get('FILE_UPLOAD.content')?.value,
          submissionType: 'FILE_UPLOAD'
        }, submit);
      }
    });
  }

  saveTextSubmissionPart(submit = false) {
    if (!this.submissionForm) {
      return;
    }
    const submissionPart: AssignmentSubmissionPart = {
      id: this.submissionForm.get('TEXT.id')?.value,
      submissionType: 'TEXT',
      content: this.submissionForm.get('TEXT.content')?.value,
    };
    this.submissionForm.get('TEXT.submitted')?.patchValue(submit);
    this.submissionForm.get('TEXT.submitted')?.updateValueAndValidity();
    this.saveSubmissionPart(submissionPart, submit);
  }

  saveSubmissionPart(submissionPart: AssignmentSubmissionPart, submit = false) {
    const request: AssignmentSubmissionPart = {
      ...submissionPart,
      submitted: submit
    };
    this.learningActivityService.saveSubmissionPart(this.learningActivityId, request).subscribe({
      next: learningActivity => {
        this.submissionChange.emit(learningActivity.submission);
        this.progressChange.emit(learningActivity.progress);
      }
    });
  }

  completeSubmission() {
    if (this.submissionForm?.invalid) {
      this.toastService.showDangerToast('Please fill all required fields before submitting.');
      return;
    }
    this.modalService.confirm('Are you sure you want to submit? You will not be able to make changes after submission.')
      .subscribe({
        next: (confirmed) => {
          if (confirmed) {
            this.learningActivityService.completeLearningActivity(this.learningActivityId)
              .pipe(catchError(err => {
                if (err.status === 400) {
                  this.modalService.alert({
                    title: 'Error',
                    content: err.error.message || 'An error occurred while submitting the assignment. Please try again later.',
                    type: 'danger'
                  });
                }

                return of(null);
              }))
              .subscribe({
                next: learningActivity => {
                  if (!learningActivity) {
                    this.toastService.showDangerToast('An error occurred while submitting the assignment. Please try again later.');
                    return;
                  }
                  this.submissionChange.emit(learningActivity.submission);
                  this.progressChange.emit(learningActivity.progress);
                  this.toastService.showSuccessToast('Assignment submitted successfully.');
                }
              });
          }
        }
      });
  }

  addLinkSubmission() {
    if (this.linkText && this.linkText.match(this.urlRegex)) {
      this.linkSubmissions.push(this.linkText);
      this.linkText = '';
      this.submissionForm?.get('LINK.submitted')?.updateValueAndValidity();
    }
  }

  saveLinkSubmissionPart(submit = false) {
    const submissionPart: AssignmentSubmissionPart = {
      id: this.submissionForm?.get('LINK.id')?.value,
      submissionType: 'LINK',
      content: this.linkSubmissions.join(' '),
    };

    this.submissionForm?.get('LINK.submitted')?.patchValue(submit);
    this.saveSubmissionPart(submissionPart, submit);
  }

  viewSubmissionFile() {
    this.loadingViewFileUrl = true;
    let fileUrl = this.submissionForm?.get('FILE_UPLOAD.content')?.value;

    if (!fileUrl) {
      this.toastService.showDangerToast('No file found to view.');
      this.loadingViewFileUrl = false;
      return;
    }

    // Check to see if the URL starts with the base API URL
    if (!fileUrl.startsWith(this.appInfo.apiUrl)) {
      // Get the URL path and append it to the base API URL
      const url = new URL(fileUrl);

      // Remove /api/v1 from the base URL
      let baseUrl = this.appInfo.apiUrl.replace('/api/v1', '');

      // Remove / from the end of the base URL
      if (baseUrl.endsWith('/')) {
        baseUrl = baseUrl.slice(0, -1);
      }

      fileUrl = `${baseUrl}${url.pathname}`;
    }


    this.uploadService.retrieveFileLink(fileUrl, true).subscribe({
      next: url => {
        window.open(url, '_blank');
        this.loadingViewFileUrl = false;
      }
    });
  }

}
