<ng-container *ngIf="lessonData && !loading; else spinner">

  <div class="my-3 flex-center">
    <app-percent-circle
      [completed]="learningActivity.completed || false"
      [percent]="learningActivity.progress || 0">
    </app-percent-circle>
  </div>

  <div *ngIf="presentationFileUrl" class="my-3">
    <div class="my-3">
      <div class="text-center">
        <div class="text-muted small">Lesson</div>
        <h3 class="text-center">{{ lessonData.title }}</h3>
      </div>

      <div class="flex-center my-3 gap-1">
      <span *ngFor="let tag of lessonData.technologies" class="badge bg-info">
        {{ tag }}
      </span>
      </div>
      <div *ngIf="!isRavenLesson" class="my-3 text-muted text-center">
        {{ lessonData.description }}
      </div>
    </div>
    <div class="ratio ratio-16x9 mb-5">
      <app-office365-viewer [fileUrl]="presentationFileUrl"></app-office365-viewer>
    </div>
  </div>

  <div *ngIf="!isRavenLesson;else ravenLesson" class="my-3">
    <app-markdown-viewer [data]="lessonData.content"></app-markdown-viewer>
  </div>

  <div class="d-flex flex-column flex-sm-row gap-3 justify-content-center">

    <div *ngIf="isRavenLesson && !learningActivity.completed">
      <div class="my-3 d-flex justify-content-center">
        <a
          [href]="lessonData.sourceUrl"
          target="_blank"
          appClickEvent
          class="btn btn-primary">
          Start lesson <i class="bi bi-arrow-right ms-2"></i>
        </a>
      </div>
    </div>

    <div *ngIf="!hasAssignments" class="my-3">
      <button
        (click)="completeLesson()"
        *ngIf="!completed"
        [disabled]="completing || (learningActivity.completed || false)"
        [ngClass]=" {'btn-primary': !isRavenLesson, 'btn-outline-secondary': isRavenLesson}"
        class="btn w-100">
        <i [ngClass]="{'bi bi-check2-circle': !completing, 'spinner-border spinner-border-sm': completing}">
          {{ learningActivity.completed ? 'Completed' : 'Complete Lesson' }}
        </i>
      </button>
    </div>
  </div>
  <hr class="my-2">
  <footer
    *ngIf="isRavenLesson"
    class="text-center text-muted small">
    <p>{{ lessonData.content | extractRavenFooterText }}</p>
  </footer>

</ng-container>

<ng-template #spinner>
  <div *ngIf="showSpinner" class="my-3 flex-center">
    <app-spinner></app-spinner>
  </div>
</ng-template>

<ng-template #ravenLesson>

  <div *ngIf="lessonData" class="row justify-content-center">
    <div class="col-12 col-lg-10">
      <div class="mb-4">
        <h1 class="display-6 fw-bold mb-3 text-center ">{{ lessonData.title }}</h1>
        <div class="d-flex flex-wrap gap-2 mb-4 justify-content-center">
          <span *ngFor="let tag of lessonData.technologies" class="badge bg-secondary px-3 py-2">{{ tag }}</span>
        </div>
      </div>
      <div class="card mb-4 scrollable-content">
        <div class="card-body p-4">
          <h2 class="fs-4 fw-semibold mb-3">{{ lessonData.title }}</h2>
          <p class="h-25">{{ lessonData.content | removeRavenFooterText }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
