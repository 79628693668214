<div class="position-fixed border-bottom top-0 left-0 w-100 z-front bg-white">
  <div class="container-fluid">
    <div class="d-flex justify-content-between align-items-center">
      <div class="min-width-btn">
        <button class="btn btn-outline-primary border-0" (click)="exit()">
          {{ isInPopup ? 'Close' : 'Exit'}}
        </button>
      </div>
      <div class="flex-grow-1">
        <div class="text-center">
          <img ngSrc="assets/logos/logo-405x180.png" class="logo-size mx-2" width="405" height="180" alt="Smoothstack Learn">
        </div>
      </div>
      <div class="min-width-btn">
        <button *ngIf="!isInPopup" class="btn btn-outline-primary border-0" ngbTooltip="Open in new window" (click)="openInNewWindow()">
          <span class="bi bi-box-arrow-up-right"></span>
          <span class="visually-hidden">Open in new window</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="my-nav">
  <router-outlet></router-outlet>
</div>

<div *ngIf="learningActivity" class="position-fixed z-front bg-white bottom-0 left-0 border-top py-2 w-100">
  <div class="container">
    <div class="w-100">
      <app-activity-bottom-navigation [activity]="learningActivity"></app-activity-bottom-navigation>
    </div>
  </div>
</div>
