import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    private http: HttpClient,
    private api: ApiService,
  ) {}

  trackClick(nativeElement: any) {
    nativeElement.addEventListener('click', () => {
      this.http
        .post(this.api.endpoint(['analytics', 'clicks']), {
          url: nativeElement.href,
        })
        .subscribe();
    });
  }
}
