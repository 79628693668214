import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractRavenFooterText',
})
export class ExtractRavenFooterTextPipe implements PipeTransform {
  transform(value: string): string {
    const footerMatch = value.match(/---\s*(.*)$/s);
    return footerMatch ? footerMatch[1].trim() : '';
  }
}

@Pipe({
  name: 'removeRavenFooterText',
})
export class RemoveRavenFooterTextPipe implements PipeTransform {
  transform(value: string): string {
    // Look for the footer separator (e.g., '---') and remove it and everything after it
    const footerMatch = value.match(/---\s*(.*)$/s);
    return footerMatch ? value.replace(footerMatch[0], '') : value;
  }
}
