import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { QuizData } from '@app/api/quiz/models/quiz.model';
import {
  QuizLearningActivity,
  QuizSubmission,
} from '@app/api/models/quiz-learning-activity';
import { catchError, forkJoin, of, switchMap } from 'rxjs';
import { QuizService } from '@app/api/quiz/services/quiz.service';
import { ActivatedRoute } from '@angular/router';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';

@Component({
  selector: 'app-quiz-activity-view',
  templateUrl: './quiz-activity-view.component.html',
  styleUrls: ['./quiz-activity-view.component.sass'],
})
export class QuizActivityViewComponent implements OnInit {
  @Input() learningActivityId!: number;
  quizLearningActivity!: QuizLearningActivity;
  quiz!: QuizData;
  submission?: QuizSubmission;
  loading = false;

  constructor(
    private quizApi: QuizService,
    private route: ActivatedRoute,
    private learningActivityService: LearningActivityService,
  ) {}

  ngOnInit(): void {
    if (this.learningActivityId) {
      this.loadQuiz();
    } else {
      this.route.params.subscribe((params) => {
        this.learningActivityId = params['activityId'];
        this.loadQuiz();
      });
    }
  }

  loadQuiz() {
    this.loading = true;
    this.learningActivityService
      .getQuizLearningActivity(this.learningActivityId)
      .pipe(
        switchMap((quiz) => {
          this.quizLearningActivity = quiz;
          return forkJoin([
            this.quizApi.getQuizById(quiz.materialId),
            this.learningActivityService
              .getQuizSubmission(quiz.id)
              //prevent error from stopping the observable chain
              .pipe(catchError(() => of(undefined))),
          ]);
        }),
      )
      .subscribe({
        next: (value) => {
          this.quiz = value[0];
          this.submission = value[1];
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        },
      });
  }
}
