import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@core/services/api.service';
import { RegisterUserRequest } from '@app/api/user/models/regiser-user-request.model';
import { User } from '@app/api/user/models/user.model';
import { Observable } from 'rxjs';
import { RegisterAndEnrollTokenDetails } from '@app/api/user/models/register-and-enroll.model';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient, private api: ApiService) { }

  registerUser(registerUser: RegisterUserRequest) {
    return this.http.post<User>(this.api.endpoint('registration'), registerUser);
  }

  getTokenDetails(token: string): Observable<RegisterAndEnrollTokenDetails> {
    return this.http.get<RegisterAndEnrollTokenDetails>(
      this.api.endpoint(['registration', 'token-details']),
      {
        params: {
          token: token
        }
      }
    );
  }

}
