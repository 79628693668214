<app-landing-page-frame>

  <div class="ms-3" *ngIf="!success; else registerResponse">
    <h1 class="text-start fs-3">
      Hi <span class="fw-bold"><span class="text-primary">{{tokenDetails?.firstName}}</span> {{tokenDetails?.lastName}}</span>!
    </h1>

      <div class="mt-3 mb-4">
          You've been invited to enroll in the course:
          <h4 class="fs-4">{{tokenDetails?.courseName}}</h4>
      </div>

  </div>
  <div class="container" *ngIf="!success" [formGroup]="registrationForm">
    <div class="row">
      <div class="col-12 mb-3">
        <app-text-field controlName="emailDisabled"
                        type="email"
                        label="Email"></app-text-field>
      </div>
      <div class="col-12 mb-3">
        <app-text-field controlName="password"
                        type="password"
                        [customErrorMessages]="{ pattern: 'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character.' }"
                        label="Password"></app-text-field>
      </div>
      <div class="col-12 mb-3">
        <app-text-field controlName="confirmPassword"
                        type="password"
                        [customErrorMessages]="{
                      matches: 'Passwords do not match'
                    }"
                        label="Confirm Password"></app-text-field>
      </div>
    </div>

    <div *ngIf="errorMessage" class="text-danger my-2">
      {{errorMessage}}
    </div>

    <button [disabled]="registrationForm.invalid" type="button" class="btn btn-primary w-100" (click)="registerUser()">
      Sign Up <span *ngIf="!registering; else spinner" class="bi-box-arrow-in-right"></span>
    </button>
    <ng-template #spinner>
      <span class="spinner-border spinner-border-sm"></span>
    </ng-template>
  </div>

  <ng-template #registerResponse>
    <div class="my-2">
      <h4 class="mb-3">You're almost done!</h4>
      <p class="my-2">We've sent an email verification link to <span class="text-info">{{registrationForm.value['email']}}</span></p>
      <div class="my-3">
        <a routerLink="/login" class="btn btn-primary">Back to Login</a>
      </div>
    </div>
  </ng-template>

</app-landing-page-frame>

