import { Component, OnDestroy, OnInit } from '@angular/core';
import { LearningActivityService } from '@app/api/learning/services/learning-activity.service';
import { LearningActivity } from '@app/api/models/learning-activity.model';
import { LearningActivityQuery } from '@app/api/learning/models/learning-activity-query.model';
import { PageParams } from '@app/api/models/page.model';
import { MultiSelectOption } from '@core/components/form-controls/multi-select-field/multi-select-field.component';
import { CohortApiService } from '@app/api/training/services/cohort-api.service';
import { StudentService } from '@app/api/student/services/student.service';
import { GlobalModalService } from '@core/services/global-modal.service';
import { ActivityMaterialType } from '@app/api/categorization/models/activity-material-type.model';
import { ManageActivitiesUpdateGradeService } from '@app/api/learning/services/manage-activities-update-grade.service';
import {
  catchError,
  filter,
  finalize,
  from,
  mergeMap,
  Subscription,
  switchMap,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { GlobalToastService } from '@app/core/services/global-toast.service';
import { SortDirection } from '@core/directives/sortable.directive';
import { StringResourceService } from '@core/services/string-resource.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-learning-activities-table',
  templateUrl: './learning-activities-table.component.html',
  styleUrls: ['./learning-activities-table.component.sass'],
})
export class LearningActivitiesTableComponent implements OnInit, OnDestroy {
  allSelected = false;
  selectedActivityIds: number[] = [];

  private manageActivitiesUpdateGradeSubscription: Subscription;
  loading = false;

  search = '';
  currentPage = 0;
  totalItems = 0;
  sortDirection: SortDirection = 'desc';
  query: LearningActivityQuery & PageParams = {
    page: 0,
    size: 15,
    sort: 'id,desc',
  };

  hideFilters = false;
  cohortOptions: MultiSelectOption[] = [];
  studentOptions: MultiSelectOption[] = [];
  courseOptions: MultiSelectOption[] = [];
  moduleOptions: MultiSelectOption[] = [];
  lessonOptions: MultiSelectOption[] = [];
  activityTypeOptions: MultiSelectOption[] = [
    {
      label: 'Course',
      value: 'COURSE',
    },
    {
      label: 'Module',
      value: 'MODULE',
    },
    {
      label: 'Lesson',
      value: 'LESSON',
    },
    {
      label: 'Assignment',
      value: 'ASSIGNMENT',
    },
    {
      label: 'Quiz',
      value: 'QUIZ',
    },
  ];
  selectedTypes: ActivityMaterialType[] = [];

  learningActivities: LearningActivity[] = [];

  selectedCohortIds: number[] = [];
  selectedStudentIds: number[] = [];
  selectedCourseIds: number[] = [];
  selectedModuleIds: number[] = [];
  selectedLessonIds: number[] = [];

  selectLearningActivity?: LearningActivity;

  constructor(
    private learningActivityService: LearningActivityService,
    private cohortApi: CohortApiService,
    private studentService: StudentService,
    private globalModal: GlobalModalService,
    private globalToast: GlobalToastService,
    private manageActivitiesUpdateGradeService: ManageActivitiesUpdateGradeService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.manageActivitiesUpdateGradeSubscription =
      this.manageActivitiesUpdateGradeService.gradeUpdated.subscribe(
        ({ id, grade }) => {
          const activityToUpdate = this.learningActivities.find(
            (obj) => obj.id === id,
          );
          if (activityToUpdate) {
            activityToUpdate.submission.score = grade;
          }
        },
      );
  }

  ngOnInit() {
    this.loadLearningActivities();
  }

  loadLearningActivities() {
    this.loading = true;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        search: this.search || null,
        page: this.currentPage,
        size: this.query.size,
        sort: this.query.sort,
      },
      queryParamsHandling: 'merge',
    });
    this.learningActivityService
      .getAllLearningActivities({
        search: this.search,
        ...this.query,
        page: this.currentPage - 1,
        cohorts: this.selectedCohortIds,
        students: this.selectedStudentIds,
        parentCourses: this.selectedCourseIds,
        parentModules: this.selectedModuleIds,
        parentLessons: this.selectedLessonIds,
        materialTypes: this.selectedLessonIds?.length
          ? ['ASSIGNMENT']
          : this.selectedModuleIds?.length
          ? ['LESSON', 'ASSIGNMENT']
          : this.selectedCourseIds?.length
          ? ['MODULE']
          : ['MODULE', 'LESSON', 'ASSIGNMENT', 'QUIZ'],
        size: 15,
      })
      .subscribe((learningActivities) => {
        const filteredActivities = learningActivities.content.filter(activity => 
          !activity.parentCourse?.id && 
          !activity.parentModule?.id && 
          !activity.parentLesson?.id
        );
        this.learningActivities = filteredActivities;
        this.totalItems = filteredActivities.length;
        this.currentPage = learningActivities.number + 1;
        this.loading = false;
      });
    this.loadOptions();
  }

  loadOptions() {
    this.loadCohortOptions();
    this.loadStudentOptions();
    this.loadCourseActivityOptions();
    this.loadModuleOptions();
    this.loadLessonOptions();
  }

  loadCohortOptions(search?: string) {
    this.cohortApi
      .getAllCohorts(search, {
        sort: 'startDate,desc',
      })
      .subscribe((cohorts) => {
        this.cohortOptions = cohorts.content.map((cohort) => ({
          label: cohort.name,
          value: cohort.id,
        }));
      });
  }

  loadStudentOptions(search?: string) {
    this.studentService
      .getAllStudents(search, {
        size: 10,
        cohorts: this.selectedCohortIds,
        sort: ['user.firstName,asc', 'user.lastName,asc'],
      })
      .subscribe((students) => {
        this.studentOptions = students.content.map((student) => ({
          label: student.firstName + ' ' + student.lastName,
          value: student.id,
        }));
      });
  }

  loadCourseActivityOptions(search?: string) {
    const uniqueCourses: number[] = [];
    this.learningActivityService
      .getAllLearningActivities({
        search: search || '',
        materialTypes: ['COURSE'],
        cohorts: this.selectedCohortIds,
        students: this.selectedStudentIds,
        size: 10,
      })
      .subscribe((learningActivities) => {
        this.courseOptions = learningActivities.content
          .filter((activity) => {
            if (uniqueCourses.includes(activity.materialId)) {
              return false;
            }
            uniqueCourses.push(activity.materialId);
            return true;
          })
          .map((activity) => ({
            label: activity.title,
            value: activity.materialId,
          }));
      });
  }

  loadModuleOptions(search?: string) {
    this.learningActivityService
      .getAllLearningActivities({
        search: search || '',
        materialTypes: ['MODULE'],
        cohorts: this.selectedCohortIds,
        students: this.selectedStudentIds,
        parentCourses: this.selectedCourseIds,
        size: 10,
      })
      .subscribe((learningActivities) => {
        const uniqueModules: number[] = [];
        this.moduleOptions = learningActivities.content
          .filter((activity) => {
            if (uniqueModules.includes(activity.materialId)) {
              return false;
            }
            uniqueModules.push(activity.materialId);
            return true;
          })
          .map((activity) => ({
            label: activity.title,
            value: activity.materialId,
          }));
      });
  }

  loadLessonOptions(search?: string) {
    this.learningActivityService
      .getAllLearningActivities({
        search: search || '',
        materialTypes: ['LESSON'],
        cohorts: this.selectedCohortIds,
        students: this.selectedStudentIds,
        parentModules: this.selectedModuleIds,
        size: 10,
      })
      .subscribe((learningActivities) => {
        const uniqueLessons: number[] = [];
        this.lessonOptions = learningActivities.content
          .filter((activity) => {
            if (uniqueLessons.includes(activity.materialId)) {
              return false;
            }
            uniqueLessons.push(activity.materialId);
            return true;
          })
          .map((activity) => ({
            label: activity.title,
            value: activity.materialId,
          }));
      });
  }

  onCohortSearchChange(search: string) {
    this.loadCohortOptions(search);
  }

  onStudentSearchChange(search: string) {
    this.loadStudentOptions(search);
  }

  onCourseSearchChange(search: string) {
    this.loadCourseActivityOptions(search);
  }

  onModuleSearchChange(search: string) {
    this.loadModuleOptions(search);
  }

  onLessonSearchChange(search: string) {
    this.loadLessonOptions(search);
  }

  previewActivity(activity: LearningActivity) {
    this.selectLearningActivity = activity;
  }

  showDeleteActivityModal(activity: LearningActivity) {
    this.globalModal
      .confirmDelete(
        'Are you sure you want to delete this learning activity? This will also delete any submissions and progress for this activity as well as all child activities.',
        activity.title,
      )
      .subscribe((result) => {
        if (result) {
          this.deleteActivity(activity);
        }
      });
  }

  deleteActivity(activity: LearningActivity) {
    this.learningActivityService
      .deleteLearningActivity(activity.id)
      .subscribe(() => {
        this.loadLearningActivities();
      });
  }

  onActivitySearch(search: string) {
    this.search = search;
    this.loadLearningActivities();
  }

  ngOnDestroy(): void {
    this.manageActivitiesUpdateGradeSubscription.unsubscribe();
  }

  selectAll(event: any) {
    if (event && event.target && typeof event.target.checked === 'boolean') {
      this.allSelected = event.target.checked;
      this.selectedActivityIds = this.allSelected
        ? this.learningActivities.map((activity) => activity.id)
        : [];
    }
  }

  toggleSelection(activityId: number, event: any) {
    if (event && event.target && typeof event.target.checked === 'boolean') {
      if (event.target.checked) {
        this.selectedActivityIds.push(activityId);
      } else {
        const index = this.selectedActivityIds.indexOf(activityId);
        if (index !== -1) {
          this.selectedActivityIds.splice(index, 1);
        }
      }
    }
  }

  isSelected(activityId: number): boolean {
    return this.selectedActivityIds.includes(activityId);
  }

  anyActivitySelected(): boolean {
    return this.selectedActivityIds.length > 0;
  }

  deleteSelectedActivities() {
    const message = 'Are you sure you want to delete the selected activities?';

    this.globalModal
      .confirm(message)
      .pipe(
        filter((result) => result),
        switchMap(() => {
          const maxRetries = 5;
          let retryCount = 0;

          return from(this.selectedActivityIds).pipe(
            mergeMap((activityId) =>
              this.learningActivityService
                .deleteLearningActivity(activityId)
                .pipe(
                  catchError((error) => {
                    if (++retryCount < maxRetries) {
                      return timer(1000).pipe(
                        mergeMap(() =>
                          this.learningActivityService.deleteLearningActivity(
                            activityId,
                          ),
                        ),
                      );
                    } else {
                      return throwError(() => error);
                    }
                  }),
                ),
            ),
            tap(() => {
              console.log('All selected activities have been deleted.');
            }),
            finalize(() => {
              this.loadLearningActivities();
              const numberOfActivitiesDeleted = this.selectedActivityIds.length;
              this.globalToast.showSuccessToast(
                `${numberOfActivitiesDeleted} activities deleted successfully`,
              );
              this.selectedActivityIds = [];
              this.allSelected = false;
            }),
          );
        }),
      )
      .subscribe();
  }

  onSort(sort: string) {
    if (sort === 'type') {
      this.query.sort = `material.materialType,${this.sortDirection}`;
    } else {
      this.query.sort = `${sort},${this.sortDirection}`;
    }
    this.loadLearningActivities();
  }

  onSortDirectionChange() {
    const querySort = this.query.sort as string;
    const [column, _] = querySort.split(',');
    this.query.sort = `${column},${this.sortDirection}`;
    this.loadLearningActivities();
  }

  getLabel(): string {
    if (this.query.sort) {
      //cast to string
      const sort = this.query.sort as string;
      const [column, direction] = sort.split(',');
      if (column === 'material.materialType') {
        return 'Type';
      }
      return StringResourceService.sentenceCase(column);
    }
    return 'ID';
  }

  onPageChange($event: number) {
    this.currentPage = $event;
    this.loadLearningActivities();
  }
}
