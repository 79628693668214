import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QuestionData } from '@app/api/question/models/question-data.model';
import {
  QuestionType,
  QuestionTypes,
} from '@app/api/question/models/question-data-types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '@core/validation/custom-validators';
import { CreateQuestionRequest } from '@app/api/question/models/create-question-request.model';
import { QuestionFormService } from '@app/api/question/services/question-form.service';
import { UserService } from '@app/api/user/services/user.service';
import { UserRole } from '@app/api/user/models/user.model';

@Component({
  selector: 'app-question-preview',
  templateUrl: './question-preview.component.html',
  styleUrls: ['./question-preview.component.sass'],
})
export class QuestionPreviewComponent implements OnChanges {
  @Input() data?: QuestionData;

  @Input() set requestData(requestData: CreateQuestionRequest) {
    this.data = this.formService.mapRequestToQuestionData(requestData);
  }

  correctAnswerCollapsed = true;
  correctIndexes: number[] = [];
  submitted = false;
  questionType?: QuestionType;
  previewForm: FormGroup;
  revealCorrectAnswer = false;

  constructor(
    private fb: FormBuilder,
    private formService: QuestionFormService,
    private userService: UserService,
  ) {
    this.previewForm = this.fb.group({
      mcAnswer: [[]],
      tfAnswer: [null],
      fitbAnswer: [[]],
      saAnswer: [''],
    });

    this.previewForm.valueChanges.subscribe({
      next: () => {
        this.setCorrectIndexes();
      },
    });

    this.userService.getSelf().subscribe({
      next: (user) => {
        const allowedRoles = [
          UserRole.Admin,
          UserRole.Trainer,
          UserRole.Super_Admin,
          UserRole.Training_Assistant,
        ];
        this.revealCorrectAnswer = allowedRoles.includes(user.role);
      },
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.questionType = this.data?.type;
      // Set each field to required for each type
      if (this.questionType === QuestionTypes.MULTIPLE_CHOICE) {
        this.resetValidators();
        this.previewForm
          .get('mcAnswer')
          ?.setValidators([CustomValidators.requiredMinArrayLength(1)]);
      } else if (this.questionType === QuestionTypes.TRUE_FALSE) {
        this.resetValidators();
        this.previewForm.get('tfAnswer')?.setValidators([Validators.required]);
      } else if (this.questionType === QuestionTypes.FILL_IN_THE_BLANK) {
        this.resetValidators();
        this.previewForm
          .get('fitbAnswer')
          ?.setValidators([
            CustomValidators.requiredMinArrayLength(
              this.getCorrectFitbAnswers().length,
            ),
          ]);
      } else if (this.questionType === QuestionTypes.SHORT_ANSWER) {
        this.resetValidators();
        this.previewForm.get('saAnswer')?.setValidators([Validators.required]);
      }
    }
  }

  // Remove all validators from each field
  resetValidators() {
    this.previewForm.get('mcAnswer')?.setValidators([]);
    this.previewForm.get('tfAnswer')?.setValidators([]);
    this.previewForm.get('fitbAnswer')?.setValidators([]);
    this.previewForm.get('saAnswer')?.setValidators([]);
  }

  getQuestionTypeLabel(questionType: QuestionType): string {
    switch (questionType) {
      case QuestionTypes.MULTIPLE_CHOICE:
        return 'Multiple Choice';
      case QuestionTypes.TRUE_FALSE:
        return 'True/False';
      case QuestionTypes.FILL_IN_THE_BLANK:
        return 'Fill in the Blank';
      case QuestionTypes.SHORT_ANSWER:
        return 'Short Answer';
      case QuestionTypes.INTERVIEW:
        return 'Interview';
      default:
        return 'Unknown';
    }
  }

  getCorrectFitbAnswers(): string[] {
    // Return an array of correct answers for fill in the blank questions
    const correctAnswers: string[] = [];
    const fitbAnswerRegex = /\{([^}|]+)}|\{([^}]+)\|([^}]+)}/g;

    let match: RegExpExecArray | null;
    while (
      (match = fitbAnswerRegex.exec(this.data?.fillInTheBlankText ?? '')) !==
      null
    ) {
      for (let i = 0; i < match.length; i++) {
        if (match[i]) {
          correctAnswers.push(match[i]);
          break;
        }
      }
    }

    return correctAnswers;
  }

  setCorrectIndexes() {
    // Return an array of indexes of correct answers for fill in the blank questions
    const correctIndexes: number[] = [];

    const correctAnswers = this.getCorrectFitbAnswers();

    const currentAnswers = this.previewForm.value['fitbAnswer'] as string[];

    if (!currentAnswers) {
      return;
    }

    const correctAnswerSets: string[][] = [];

    for (let i = 0; i < correctAnswers.length; i++) {
      const answer = correctAnswers[i].substring(
        1,
        correctAnswers[i].length - 1,
      );
      correctAnswerSets.push(answer.split('|'));
    }

    for (let i = 0; i < currentAnswers.length; i++) {
      if (correctAnswerSets[i].includes(currentAnswers[i])) {
        correctIndexes.push(i);
      }
    }

    this.correctIndexes = correctIndexes;
  }

  resetPreview() {
    this.previewForm.enable();
    this.previewForm.reset();
    this.submitted = false;
  }

  submitPreviewAnswer() {
    this.submitted = true;
    this.previewForm.disable();
  }

  checkAnswer() {
    if (!this.data) {
      return false;
    }
    const questionType = this.data.type;
    if (questionType === QuestionTypes.MULTIPLE_CHOICE) {
      if (!this.data.choices) {
        return;
      }

      const correctChoiceIndexes: number[] = [];
      for (let i = 0; i < this.data.choices.length; i++) {
        if (this.data.choices[i].correct) {
          correctChoiceIndexes.push(i);
        }
      }

      const selectedChoiceIndexes = this.previewForm.value[
        'mcAnswer'
      ] as number[];

      if (selectedChoiceIndexes.length !== correctChoiceIndexes.length) {
        return false;
      }

      for (let i = 0; i < selectedChoiceIndexes.length; i++) {
        if (!correctChoiceIndexes.includes(selectedChoiceIndexes[i])) {
          return false;
        }
      }

      return true;
    } else if (questionType === QuestionTypes.TRUE_FALSE) {
      const correctAnswer = this.data.trueFalseCorrect;

      const selectedAnswer = this.previewForm.value['tfAnswer'] as boolean;

      return correctAnswer === selectedAnswer;
    } else if (questionType === QuestionTypes.SHORT_ANSWER) {
      const correctAnswer = this.data.correctAnswer?.correctAnswer;

      const selectedAnswer = this.previewForm.value['saAnswer'] as string;

      const mustBeExact = this.data.mustBeExact;

      if (mustBeExact) {
        return correctAnswer === selectedAnswer;
      }

      return true;
    } else if (questionType === QuestionTypes.FILL_IN_THE_BLANK) {
      return this.correctIndexes.length === this.getCorrectFitbAnswers().length;
    }

    return false;
  }

  get getCorrectAnswerText() {
    if (!this.data) {
      return '';
    }
    const questionType = this.data.type;
    switch (questionType) {
      case QuestionTypes.MULTIPLE_CHOICE:
        if (!this.data.choices) {
          return '';
        }
        return this.data.choices
          .filter((choice) => choice.correct)
          .map((choice) => choice.choiceText)
          .join(', ');
      case QuestionTypes.TRUE_FALSE:
        return this.data.trueFalseCorrect ? 'True' : 'False';
      case QuestionTypes.SHORT_ANSWER:
        return this.data.correctAnswer?.correctAnswer ?? '';
      case QuestionTypes.FILL_IN_THE_BLANK:
        return this.getCorrectFitbAnswers().join(', ');
      default:
        return '';
    }
  }

  get getCorrectAnswerExplanation() {
    return (
      this.data?.correctAnswer?.explanation ?? this.data?.trueFalseExplanation
    );
  }
}
