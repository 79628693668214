<app-landing-page-frame>

  <div class="ms-3" *ngIf="!success; else registerResponse">
    <h1 class="text-start fs-3">
      Register
    </h1>

    <p class="text-start">
      Sign up for Smoothstack Learn.
    </p>
  </div>
  <div class="container" *ngIf="!success" [formGroup]="registrationForm">
    <div class="row">
      <div class="col-12 col-md-6 mb-3">
        <app-text-field controlName="firstName"
                        type="text"
                        label="First Name"></app-text-field>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <app-text-field controlName="lastName"
                        type="text"
                        label="Last Name"></app-text-field>
      </div>
      <div class="col-12 mb-3">
        <app-text-field controlName="email"
                        type="email"
                        label="Email"></app-text-field>
      </div>
      <div class="col-12 mb-3">
        <app-text-field controlName="password"
                        type="password"
                        [customErrorMessages]="{ pattern: 'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character.' }"
                        label="Password"></app-text-field>
      </div>
      <div class="col-12 mb-3">
        <app-text-field controlName="confirmPassword"
                        type="password"
                        [customErrorMessages]="{
                      matches: 'Passwords do not match'
                    }"
                        label="Confirm Password"></app-text-field>
      </div>
    </div>

    <div class="mb-3">
      <div class="form-switch">
        <input [(ngModel)]="hasPromoCode" [ngModelOptions]="{standalone: true}" type="checkbox" class="form-check-input" id="hasPromoCode"/>
        <label for="hasPromoCode" class="form-check-label ms-2 text-muted">I have a promo code</label>
      </div>
    </div>

    <div *ngIf="hasPromoCode" class="mb-3">
      <div class="form-floating">
        <input id="promoCode" formControlName="promoCode" type="text" class="form-control" placeholder="Promo Code"/>
        <label for="promoCode" class="form-label">Promo Code</label>
      </div>
    </div>

    <div *ngIf="errorMessage" class="text-danger my-2">
      {{errorMessage}}
    </div>

    <button [disabled]="registrationForm.invalid" type="button" class="btn btn-primary w-100" (click)="registerUser()">
      Sign Up <span *ngIf="!registering; else spinner" class="bi-box-arrow-in-right"></span>
    </button>
    <ng-template #spinner>
      <span class="spinner-border spinner-border-sm"></span>
    </ng-template>
  </div>

  <ng-template #registerResponse>
    <div class="my-2">
      <h4 class="mb-3">You're almost done!</h4>
      <p class="my-2">We've sent an email verification link to <span class="text-info">{{registrationForm.value['email']}}</span></p>
      <div class="my-3">
        <a routerLink="/login" class="btn btn-primary">Back to Login</a>
      </div>
    </div>
  </ng-template>

</app-landing-page-frame>

