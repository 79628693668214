import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/api/user/services/user.service';
import { User, UserRole } from '@app/api/user/models/user.model';
import { UserFilterParams } from '@app/api/user/models/user-filter-params.model';
import { AuthService } from '@app/auth/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-view-users-table',
  templateUrl: './view-users-table.component.html',
  styleUrls: ['./view-users-table.component.sass']
})
export class ViewUsersTableComponent implements OnInit {

  pagination = {
    page: 0,
    size: 10,
    currentPageSize: 0,
    total: 0,
    sortBy: 'id',
    direction: 'asc',
  };
  searchQuery = '';
  filters: UserFilterParams = {};
  users: User[] = [];


  constructor(private userService: UserService, private authService: AuthService,
    private route: ActivatedRoute, private router: Router) { }

  getAllUsers(reset?: boolean) {
    if (reset) {
      this.pagination.page = 0;
      this.pagination.currentPageSize = 0;
      this.pagination.total = 0;
    }

    const queryParams: any = {
      page: this.pagination.page,
      size: this.pagination.size,
      sort: this.pagination.sortBy + ',' + this.pagination.direction,
    };

    if (this.searchQuery.trim()) {
      queryParams.search = this.searchQuery;
    } else {
      queryParams.search = null;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true
    });

    return this.userService.getAllUsers(this.searchQuery, this.filters, {
      page: this.pagination.page - 1,
      size: this.pagination.size,
      sort: this.pagination.sortBy + ',' + this.pagination.direction
    })
      .subscribe(users => {
        this.users = users.content.filter(user => user.id > 0);
        this.pagination.currentPageSize = users.numberOfElements;
        this.pagination.total = users.totalElements;
      });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.searchQuery = params['search'] || '';
      this.getAllUsers();
    });
  }

  userCellClass(user: User) {
    return {
      'text-black-50 bg-light-subtle': !user.enabled
    };
  }

  onUserDeleted() {
    this.getAllUsers();
  }

  emptyRows() {
    return Array(this.pagination.size - this.pagination.currentPageSize);
  }

  mapPropertyToLabel(property: string) {
    switch (property) {
      case 'id':
        return 'ID';
      case 'email':
        return 'Email';
      case 'firstName':
        return 'First Name';
      case 'lastName':
        return 'Last Name';
      case 'picture':
        return 'Profile Picture';
      case 'emailVerified':
        return 'Email Verified';
      case 'emailVerifiedAt':
        return 'Email Verified At';
      case 'role':
        return 'Role';
      case 'roles':
        return 'Roles';
      case 'createdAt':
        return 'Created At';
      case 'updatedAt':
        return 'Updated At';
      case 'enabled':
        return 'Enabled';
      default:
        return property;
    }
  }

  impersonateUser(username: string) {
    this.userService.impersonateUser(username).subscribe({
      next: (response) => {
        console.log('Impersonation successful:', response);
      },
      error: (error) => {
        console.error('Impersonation failed:', error);
      }
    });
  }

  canImpersonate(user: User): boolean {
    return user.roles.includes(UserRole.Student) || user.roles.includes(UserRole.Trainer);
  }
}
