<div *ngIf="!loading && module && moduleActivity; else loader" class="container">

  <div *ngIf="moduleActivity && moduleActivity.parentCourse" class="container">
    <div class="my-2">
      <button [routerLink]="learningRouteData.getCoursePathArray()" class="btn btn-outline-primary border-0">
        <div class="d-flex align-items-start">
          <span class="bi bi-chevron-left me-2 flex-shrink-1"></span>
          <div class="text-start">
            <div class="small">
              <span class="fw-bold">Back to Course</span>
            </div>
            {{ moduleActivity.parentCourse.title }}
          </div>
        </div>
      </button>
    </div>
  </div>

  <div class="my-5 pt-3 flex-center flex-column">
    <div class="mb-3">
      <app-percent-circle [percent]="moduleActivity.progress"></app-percent-circle>
    </div>
    <div class="text-center">
      <div class="text-muted small">
        Module
      </div>
      <h3 class="text-center">
        {{ module.title }}
      </h3>
    </div>
    <div *ngIf="module.technologies && module.technologies.length" class="my-2 flex-center gap-1">
      <span *ngFor="let tag of module.technologies" class="badge rounded-pill small bg-info text-white">
        {{ tag }}
      </span>
    </div>


    <div *ngIf="isRavenModule;else moduleDes" class="my-3">
      <app-markdown-viewer [data]="module.description"></app-markdown-viewer>
      <div class=" d-flex justify-content-center">
        <a
          appClickEvent
          [href]="module.sourceUrl" class="btn btn-outline-primary" target="_blank">
          Start module <span class="bi bi-box-arrow-up-right"></span>
        </a>
      </div>
    </div>
    <ng-template #moduleDes>
      <p class="my-2 text-muted">
        {{ module.description }}
      </p>
    </ng-template>
  </div>
  <div class="row">
    <div class="col-12 col-md-8 offset-md-2">
      <div class="list-group" ngbAccordion>
        <div *ngFor="let activity of moduleActivity.linkedActivities" ngbAccordionItem>
          <button
            [routerLink]="!activity.linkedActivities || !activity.linkedActivities.length ? getRoutePath(activity) : undefined"
            class="list-group-item list-group-item-action cursor-pointer"
            ngbAccordionHeader>
            <div class="d-flex align-items-center justify-content-between" ngbAccordionToggle>
              <div class="d-flex align-items-center">
                <span *ngIf="activity.type ==='LESSON'" class="bi bi-bookmark-fill text-success fs-2 mx-3"></span>
                <span *ngIf="activity.type ==='QUIZ'" class="bi bi-patch-question text-success fs-2 mx-3"></span>

                <div class="my-3">
                  <div class="text-muted small">
                    {{ activity.type }}
                  </div>
                  <a [routerLink]="getRoutePath(activity)" class="text-black fw-bold text-decoration-none">
                    {{ activity.order + 1 }}. {{ activity.title }}
                  </a>
                </div>
              </div>

              <div class="d-flex align-items-center">
                <div class="mx-2">
                  <app-percent-circle [percent]="activity.progress" size="xxs"></app-percent-circle>
                </div>
                <button [routerLink]="getRoutePath(activity)" class="btn btn-link" click-stop-propagation
                        type="button">
                  <span class="bi bi-chevron-right text-primary mx-3 fs-5">
                    <span class="visually-hidden">Go to {{ activity.type | titlecase }}</span>
                  </span>
                </button>
              </div>
            </div>
          </button>
          <div ngbAccordionCollapse>
            <div class="p-0 m-0" ngbAccordionBody>
              <ng-template>
                <div class="list-group list-group-flush p-0 m-0">
                  <button
                    *ngFor="let linkedActivity of activity.linkedActivities"
                    [routerLink]="['./',activity.type.toLowerCase(), linkedActivity.id, 'activity', linkedActivity.id ]"
                    class="list-group-item list-group-item-action">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">

                        <span *ngIf="linkedActivity.type==='ASSIGNMENT'"
                              class="bi bi-file-earmark-text-fill text-info fs-2 mx-3"></span>
                        <span *ngIf="linkedActivity.type==='QUIZ'"
                              class="bi bi-patch-question text-info fs-2 mx-3"></span>
                        <div class="my-3">
                          <div class="text-muted small">
                            {{ linkedActivity.type }}
                          </div>
                          <div class="fw-bold">
                            {{ linkedActivity.order + 1 }}. {{ linkedActivity.title }}
                          </div>
                        </div>
                      </div>

                      <div class="d-flex align-items-center">
                        <div class="mx-2">
                          <app-percent-circle [percent]="linkedActivity.progress" size="xxs"></app-percent-circle>
                        </div>
                        <span class="bi bi-chevron-right text-primary mx-3 fs-5">
                        <span class="visually-hidden">Go to {{ linkedActivity.type|titlecase }}</span>
                      </span>
                      </div>
                    </div>
                  </button>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loader>
  <app-activity-skeleton-structure></app-activity-skeleton-structure>
</ng-template>
